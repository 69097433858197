import React from 'react';
import {isServerSide} from 'tunnel-commande/src/utils';

export class LiveAgent extends React.Component {
  private loaded: boolean;
  private timeoutId: any;
  private readonly delai: number;

  constructor(props: any) {
    super(props);
    this.loaded = false;
    this.delai = 1000;
    this.timeoutId = null;
  }

  componentDidMount(): void {
    this.config();
  }

  config(): void{
    if(!isServerSide()) {
      if (!this.loaded) {
        const lc = document.createElement('script');
        lc.async = true;
        lc.innerHTML = this.contentScript();
        const s = document.getElementsByTagName('script')[0];

        if(s?.parentNode) {
          s.parentNode.insertBefore(lc, s);
        }

        lc.addEventListener('load', () => {
          this.loaded = true;
          if(this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
          this.timeoutId = setTimeout(() => {
            this.timeoutId = null;
          }, this.delai);
        });
      }
    }
  }

  private contentScript() {
    return (`(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
          'https://designpartner.ladesk.com/scripts/track.js',
            function(e){ LiveAgent.createButton('wxdiaia1', e); });
          `);
  }

  render() {
    return null;
  }
}

export default LiveAgent;
