import React from 'react';
import { Col, Row } from 'reactstrap';
import {withTranslation} from 'react-i18next';
import Social from './Social';
import { renderLink } from '../utils';

class NavFooter extends React.Component<any, any> {
  faqLink: string;

  constructor(props: any) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapsed: false,
      paiementColors: true
    };
    this.faqLink =  this.props.isNewFaqEnabled ? '/help' : '/faq';
  }

  toggle = (_isBurger : boolean = false) => {
    this.setState({ collapsed: !this.state.collapsed});
  }

  render() {
    const renderLegalLinks = () => {
      return (
        <>
          <li>{renderLink(`/${this.props.i18n.language}/faq/conditions-generales-de-vente`, this.props.t('terms_of_sales'), '/faq?tag=conditions-generales-de-vente')}</li>
          <li>{renderLink(`/${this.props.i18n.language}/faq/politique-des-retours`, this.props.t("returns_policy"), '/faq?tag=politique-des-retours')}</li>
          <li>{renderLink(`/${this.props.i18n.language}/faq/politique-generale-de-protection-des-donnees`, this.props.t('privacy_policy'), '/faq?tag=politique-generale-de-protection-des-donnees')}</li>
          <li>{renderLink(`/${this.props.i18n.language}/faq/politique-de-cookies`, this.props.t('cookies_policy'), '/faq?tag=politique-de-cookies')}</li>
          <li>{renderLink(`/${this.props.i18n.language}/faq/mentions-legales`, this.props.t('legal_notice'), '/faq?tag=mentions-legales')}</li>
        </>
      );
    };

    const renderFooterNavigation = () => {
      return (
        <>
        <Col xs={{size: 12}} sm={{size: 12}} md={{size: 3}} className="">
          <div className={'text-uppercase bold'}>{this.props.t('about_us')}</div>
          <ul className={'list-unstyled py-2'}>
            <li>{renderLink('/'+this.props.i18n.language+'/about-us', this.props.t('about_us'), '/about-us')}</li>
            <li>{renderLink('/'+this.props.i18n.language+'/faq/moyens-de-paiement', this.props.t('means_of_payment'), '/faq?tag=moyens-de-paiement')}</li>
          </ul>
        </Col>
        <Col xs={{size: 12}} sm={{size: 12}} md={{size: 3}} className="">
          <div className={'text-uppercase bold'}>{this.props.t('need_help')}</div>
          <ul className={'list-unstyled py-2'}>
            <li>{renderLink(`/${this.props.i18n.language}${this.faqLink}`, this.props.t('faq'), this.faqLink)}</li>
          </ul>
        </Col>
        <Col xs={{size: 12}} sm={{size: 12}} md={{size: 3}} className="">
          <div className={'text-uppercase bold'}>{this.props.t('about_this_site')}</div>
          <ul className={'list-unstyled py-2'}>
            {renderLegalLinks()}
          </ul>
        </Col>
        </>
      );
    };

    const renderSocial = () => {
      return (
        <Col xs={{size: 12}} sm={{size: 12}} md={{size: 3}} className="">
          <div className={'text-uppercase bold'}>{this.props.t('follow_us')}</div>
          <Social />
        </Col>
      );
    };

    return (
      <>
        <div className={' navFooter container-fluid'}>
          <div className={' container-fluid footer pb-0 container-fluid-dt'}>
          <Row className={'py-4  font13'}>
            {renderFooterNavigation()}
            {renderSocial()}
          </Row>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation("footer")(NavFooter);
