import React from "react";
import {ShopInfo} from "../repositories/shop/ShopRepositorySchemas";

export const ShopInfoContext = React.createContext<ShopInfo>(null as any);

export interface ShopInfoProps {
  shopInfo: ShopInfo
}

export function withShopInfo<T>(WrappedComponent: React.ComponentType<T & ShopInfoProps>) {
  const WithShopInfo = (props: Omit<T, keyof ShopInfoProps>) => {
    return (
      <ShopInfoContext.Consumer>
        {(value) => {
          return <WrappedComponent {...(props as T)} shopInfo={value}/>
        }}
      </ShopInfoContext.Consumer>
    )
  };

  WithShopInfo.WrappedComponent = WrappedComponent;

  return WithShopInfo;
}
