import React from 'react';
import Logos from './Logos';
import Icons from './Icons';
import LngSelect from './LngSelect';
import AccountIcon from './AccountIcon';
import CartIcon from './CartIcon';
import {
  Col,
  Row,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav as BootstrapNav,
  NavItem,
  PopoverBody, UncontrolledPopover
} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import {getShopInfosInStorage} from "tunnel-commande/src/components/cart/ShopInfos";

import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import {Category} from "../repositories/category/CategoryRepositorySchemas";

class Nav extends React.Component<any, any> {
  private lastScrollYPosition: number;
  private lng: any;
  private readonly nextFab: any = null;
  private _isMounted: boolean;
  private linkDTAcademy: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.lastScrollYPosition = 0;
    this.lng = this.props.i18n.language;

    this.linkDTAcademy = '';

    const localeMoment = this.lng === 'fr' ? this.lng : 'en-gb';
    moment.locale(localeMoment);

    this.nextFab = getShopInfosInStorage() && getShopInfosInStorage().prochaineFabrication ?  getShopInfosInStorage().prochaineFabrication : {};

    this.toggle = this.toggle.bind(this);
    this.badgesToggle = this.badgesToggle.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.clickWindow = this.clickWindow.bind(this);
    this.onWindowScroll = this.onWindowScroll.bind(this);
    this.setPopoverOpen = this.setPopoverOpen.bind(this);

    this.state = {
      collapsed: this.props.collapsed,
      smallDevice: false,
      menuFixed: false,
      openPopover: false
    };
  }

  static getInitialProps({query}: {query: any}) {
    return {token: query.token, namespacesRequired: ['common']};
  }

  componentDidMount(): void {
    this.linkDt();
    if(typeof window !== 'undefined') {
      window.addEventListener("resize", this.onWindowResize);
      window.addEventListener("scroll", this.onWindowScroll);
      window.addEventListener("click", this.clickWindow, false);
    }
    this._isMounted = true;
    this.lng = this.props.i18n.language;
    const localeMoment = this.lng === 'fr' ? this.lng : 'en-gb';
    moment.locale(localeMoment);
    if(document.documentElement.clientWidth < 992 && !this.state.smallDevice) {
      this.setState({smallDevice: true});
    } else if(document.documentElement.clientWidth > 992 && this.state.smallDevice ) {
      this.setState({smallDevice: false});
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if(this.props.loading && this.state.collapsed) {
      //this.setState({collapsed: false});
    }
    if(this.props.i18n.language !== prevProps.i18n.language || this.props.i18n.language !== this.lng ) {
      this.lng = this.props.i18n.language;
      const localeMoment = this.lng === 'fr' ? this.lng : 'en-gb';
      moment.locale(localeMoment);
      this.linkDt();
      return true;
    }
  }


  componentWillUnmount(): void {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("scroll", this.onWindowScroll);
    window.removeEventListener("click", this.clickWindow, false);
    this._isMounted = false;
  }

  private setPopoverOpen() {
    this.setState({openPopover: !this.state.openPopover} , () => { /** */ });
  }

  private clickWindow(e: any) {
    if(this._isMounted) {
      const elt = e.path;
      if (elt && this.state.collapsed) {
        let isMenu = false;
        elt.forEach(function (item: any) {
          if (item && item.classList && item.classList.contains('main-navigation')) {
            isMenu = true;
          }
        });
        if (!isMenu) {
          this.setState({collapsed : false}, () => {
            this.changeStyleNavBar();
          });
        }
      }
    }
  }

  private isScrollUp() {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    let scrollUp = false;
    if (st > this.lastScrollYPosition){
      // downscroll code
      scrollUp = false;
    } else {
      // upscroll code
      scrollUp = true;
    }
    this.lastScrollYPosition = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    return scrollUp;
  }

  private onWindowScroll(e:any) {
    const scrollUp = this.isScrollUp();
    if(window.scrollY >= 100 && this.state.smallDevice && !this.state.menuFixed && scrollUp) {
      this.setState({
        menuFixed: true
      });
    } else if((window.scrollY < 100 && this.state.menuFixed) || (!scrollUp && this.state.menuFixed) ) {
      this.setState({
        menuFixed: false
      });
    }
  }

  private onWindowResize() {
    if (document.documentElement.clientWidth < 992 && !this.state.smallDevice) {
      this.setState({
        smallDevice: true,
        collapsed: false
      }, () => {
        this.changeStyleNavBar();
      });
    } else if (document.documentElement.clientWidth > 992 && (this.state.smallDevice || this.state.collapsed)) {
      this.setState({
        smallDevice: false,
        collapsed: false
      }, () => {
        this.changeStyleNavBar();
      });
    }
  }

  private linkDt() {
    if(this.props.categories) {
     this.props.categories.map((elt: any, index: number) => {
        if (elt.IdCategorie === 448 && elt.links[this.lng]) {
          this.linkDTAcademy = elt.links[this.lng];
        }
      });
    }
  }

  private toggle(_isBurger : boolean = false) {
    this.setState({ collapsed: !this.state.collapsed});
  }

  private badgesToggle(badgesOpen : boolean = false) {
    this.setState({ badgesOpen: !this.state.badgesOpen});
  }

  private changeStyleNavBar() {
    this.props.onCollapsed(this.state.collapsed);
  }

  render() {
    const renderMainNavigation = () => {
      return (
        <Navbar id="main-navigation" className={`main-navigation container-fluid flex-column m-0 pl-0 pr-0 pb-0 pt-0 pt-lg-2 ${this.state.smallDevice ? 'main-navigation-mobile ' : ''} ${this.state.menuFixed ? 'main-navigation-fixed-top' : ''} `} light fixed={`${this.state.menuFixed ? 'top' : ''}`} expand="lg">
          {renderHead()}
          {renderMenu()}
        </Navbar>
      );
    };

    const renderShortMenu = () => {
      return (
        <Col  xs={{size: 3}} sm={{size: 9}} lg={{size: 3}} className="short-menu-container d-flex align-items-center justify-content-end p-0">
          <AccountIcon smallDevice={this.state.smallDevice} />
          <CartIcon smallDevice={this.state.smallDevice} />
          <div className="d-none d-sm-block font14"><LngSelect currentPath={this.props.currentPath} links={this.props.links} /></div>
        </Col>
      );
    };

    const renderHead = () => {
      const sizeLogo = this.state.smallDevice ? 180 : 250;
      return (
        <div className={'container-fluid container-fluid-dt p-0 justify-content-around'}>
          <Row className={`w-100 align-items-center  ${this.state.smallDevice ? 'bg-black ' : ''} ${this.state.collapsed ? 'dt-collapsed-menu ' : ''}`}>
            <Col xs={{size: 9}} sm={{size: 3}} lg={{size: 3}} className="px-0">
              <div className={'d-flex'}>
                <button id="burger" className={'d-block d-lg-none border-0 bg-transparent px-0 pl-2'} aria-label="menu" onClick={() => this.toggle()}>
                    <Icons icon="menu" size={44} className={'white'}/>
                </button>
                <NavbarBrand href={`/${this.props.i18n.language}/`} aria-label={this.props.t('home_page')} className="mr-auto ml-sm-1">
                  <Logos icon="ducktransfer" size={sizeLogo} className={'logoHeader d-none d-lg-block'}/>
                  <Logos icon="ducktransfer-white" size={sizeLogo} className={'logoHeader d-lg-none ml-2'}/>
                </NavbarBrand>
              </div>
            </Col>
            <Col xs={{size: 12}} sm={{size: 5}} lg={{size: 6}} className="d-none d-lg-block align-self-center baseLineHead ">
              <div className={'d-flex justify-content-between align-items-center'}>
                <div className={'line-height-1 flex-grow-1 baseLineHeadWrapper'}>
                  {this.props.pageHome ? (
                    <>
                  <h1 className={'text-center line-height-1 mb0 p0 font14 bold text-uppercase baseLineHeadTitle'}>{this.props.t('h1_home')}</h1></>
                    ) : (
                      <>
                    <div className={'text-center line-height-1 font14 bold text-uppercase baseLineHeadTitle'}>{this.props.t('h1_home')}</div>
                    </>
                    )}
                  <div className=" text-center font13 pt-1  grey">
                    {this.props.t('h1_baseline_home')}
                  </div>
                </div>
                {this.nextFab && this.nextFab.data?.date_livraison && this.nextFab.deadline &&
                <div id="infoNextFab" className={'font13 text-center line-height-1 cursor-help'}>
                  <div className={'grey'}>{this.props.t('serigraphic_transfer')}</div>
                  <div className={'pt-1 text-italic'}>
                    <Icons icon="local_shipping_bis" size={16} className={'mr-1'}/>
                    {moment(this.nextFab.data.date_livraison).format('dddd DD MMMM YYYY')}</div>

                  <UncontrolledPopover trigger="hover" placement="bottom" isOpen={this.state.openPopover} target="infoNextFab" toggle={this.setPopoverOpen}>
                    <PopoverBody className={'text-center'}>
                      <div className={'p-3'}>
                        {this.props.t('delivery_scheduled_for') + ' ' + moment(this.nextFab.data.date_livraison).format('dddd DD MMMM YYYY') + ' ' + this.props.t('order_placed') + ' ' }
                        <span className="bold">{this.props.t('before') + ' ' + moment(this.nextFab.deadlineDay).format('dddd DD MMMM YYYY') + ' ' + this.props.t('at') + ' ' + moment(this.nextFab.deadline, "HH:mm:ss").format('LT')}</span>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
                }
              </div>
            </Col>
            {renderShortMenu()}
          </Row>
        </div>
      );
    };

    const listingCategories = this.props.categories.map((elt: Category, index: number) => {
      const name = elt.name[this.lng];

      let className = index == 0 ? 'categoriesMenuFirst categoriesMenu' : index == this.props.categories.length-1 ? 'categoriesMenu noBorderMenu' : 'categoriesMenu';
      if(elt.IdCategorie == 448) {
        className = className + ' d-none flex-grow-1 d-lg-block ';
      }
      const classIsActive = this.props.categorie == elt.IdCategorie ? 'active' : '';
      const classStandard = elt.IdCategorie != 448 ? ' justify-content-between ' : ' justify-content-end ';
      const hrefLink = elt.oneProduct?.[this.lng] || elt.links[this.lng];
      return <NavItem key={index} className={className}>
        <a href={hrefLink || ''} className={'  d-flex align-items-center  px-3 py-3 '+classStandard+' '+classIsActive}>
                      <span className={'d-flex align-items-center spanMenu '}>
                        <Icons icon={`category-${elt.IdCategorie}`} size={20} className={'mr-1 align-text-top'}/> {name}
                      </span>
          <Icons icon="arrow_dt" size={15} className={'ml-1 d-block d-lg-none align-text-top'}/>
        </a>
      </NavItem>
    });

    const renderMenu = () => {
      const classCollapsed = this.state.collapsed ? 'menu-collapsed' : 'menu-not-collapsed';

      return (
        <div className={'navMenu container-fluid p-0'}>
          <div className={'container-fluid container-fluid-dt white p-0 zIndex5'}>
            <Collapse isOpen={this.state.collapsed} navbar className={classCollapsed}>
              <BootstrapNav className={"mr-auto align-items-start align-items-lg-center w-100"}  navbar>
                <div className={'d-flex white px-4 py-4 font-14 justify-content-between w-100 d-lg-none '+classCollapsed}>
                  <div className={'text-uppercase bold'}>
                    {this.props.t('menu')}
                  </div>
                  <div className={''}><LngSelect currentPath={this.props.currentPath} links={this.props.links} /></div>
                  <div className={'text-uppercase cursor-pointer'} onClick={() => this.toggle()}>{this.props.t('close')}<Icons icon="close" size={20} className={'ml-1 align-text-top'}/></div>
                </div>
                {listingCategories}
                {(this.state.collapsed || this.state.smallDevice) ? (
                <div className={'d-flex startSubMenuMobile align-items-start flex-wrap '}>
                  <NavItem className={'noBorderMenu  noBorderTopMenu'}>
                    <a href={this.linkDTAcademy} className={' d-flex align-items-center px-3 py-3 spanMenu '}>
                      <Icons icon="tv2" size={25} className={'mr-2 align-text-top '}/> DuckTransfer Academy
                    </a>
                  </NavItem>
                    <NavItem className={'noBorderMenu noBorderTopMenu'}>
                        <a href={`/${this.lng}/about-us`} className={' d-flex align-items-center  px-3 py-3  spanMenu'}>
                          <Icons icon="a-propos" size={20} className={'mr-2 align-text-top'}/>{this.props.t('about_us')}
                        </a>
                    </NavItem>
                  <div className={'w-50'}>
                    <NavItem className={'noBorderMenu noBorderTopMenu d-lg-none px-3 py-3'}>
                      <AccountIcon smallDevice={this.state.smallDevice} mobileMenu={true} />
                    </NavItem>
                    <NavItem className={'noBorderMenu noBorderTopMenu d-lg-none px-3 py-3'}>
                      <CartIcon smallDevice={this.state.smallDevice} mobileMenu={true} />
                    </NavItem>
                  </div>
                </div>
                  ) : (
                  <>
                    <NavItem className={'noBorderMenu noBorderTopMenu'}>
                      <a  href={`/${this.lng}/about-us`}  className={' d-flex align-items-center  px-3 py-3  '}>
                        <span className={'spanMenu'}><Icons icon="a-propos" size={18} className={'mr-2 align-text-top'}/>{this.props.t('about_us')}</span>
                      </a>
                    </NavItem>
                    <NavItem className={'noBorderMenu noBorderTopMenu d-lg-none px-3 py-3'}>
                      <AccountIcon smallDevice={this.state.smallDevice} mobileMenu={true} />
                    </NavItem>
                    <NavItem className={'noBorderMenu noBorderTopMenu d-lg-none px-3 py-3'}>
                      <CartIcon smallDevice={this.state.smallDevice} mobileMenu={true} />
                    </NavItem>
                  </>
                )}
              </BootstrapNav>
            </Collapse>
          </div>
          <div
            className={`dt-overlay-menu-mobile zIndex4 ${this.state.collapsed && 'open'}`}
            onClick={() => this.setState({ collapsed: false })}
          />
        </div>
      );
    };
    return (
      <>
        {renderMainNavigation()}
      </>
    );
  }
}
export default withTranslation()(Nav);
