import React from 'react';
import userStore from 'tunnel-commande/src/components/mon-compte/UserStore';
import {isServerSide} from 'tunnel-commande/src/utils';
import {getShopInfosInStorage} from "tunnel-commande/src/components/cart/ShopInfos";

declare let window: any;

export class LiveChat extends React.Component<any, any> {
  private loaded: boolean;
  private readonly delai: number;

  constructor(props: any) {
    super(props);
    this.loaded = false;
    this.delai = props.delai * 1000;

    this.handleUserStoreChange = this.handleUserStoreChange.bind(this);

    this.state = {
      showBtn: true,
      delay: false,
      pathname: '',
      affichage_offline_livechat: getShopInfosInStorage() ?  getShopInfosInStorage().affichage_offline_livechat : false,
      affichage_livechat: getShopInfosInStorage() ?  getShopInfosInStorage().affichage_livechat : false,
    };
  }

  componentDidMount(): void {
    if(this.state.affichage_livechat || this.state.affichage_offline_livechat) {
      this.configLiveChat();
    }
  }

  configLiveChat(): void{
    if(!isServerSide()) {
      userStore.addListener('change', this.handleUserStoreChange);
      if (!this.loaded) {
        window.__lc = window.__lc || {};
        window.__lc.license = this.props.license;
        window.__lc.group = this.props.group;
        window.__lc.chat_between_groups = this.props.chatBetweenGroups;
        window.__lc.params = [
          {name: 'origin', value: window.location.hostname}
        ];
        window.__lc.visitor = this.props.visitor;
        const lc = document.createElement('script');
        lc.async = true;
        lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') +
            'cdn.livechatinc.com/tracking.js';
        const s = document.getElementsByTagName('script')[0];

        if(s?.parentNode) {
          s.parentNode.insertBefore(lc, s);
        }

        lc.addEventListener('load', () => {
          window.LC_API.on_after_load = () => {
            this.loaded = true;
            this.handleUserStoreChange();
            this.offlineChat();
            setTimeout(() => {this.setState({delay: true}); clearTimeout();},this.delai);
          };
        });
      }
    }
  }

  private offlineChat() {
    const showLiveChatOffline = this.state.affichage_offline_livechat;
      if(!window.LC_API.agents_are_available() && !showLiveChatOffline ) {
        window.LC_API.hide_chat_window();
      }
  }

  componentWillUnmount(): void {
    userStore.removeListener('change', this.handleUserStoreChange);
  }

  private handleUserStoreChange() {
    const user: any = this.userToLiveChat(userStore.getUser());
    if(this.loaded) {
      window.LC_API.set_visitor_name(user.name);
      window.LC_API.set_visitor_email(user.email);
    }
  }

  private userToLiveChat(user: any) {
    return (user) ? {
      name: `${user.Nom} ${user.Prenom}`,
      email: user.Email
    } : {};
  }


  render() {
    return this.state.delay ? true : null;
  }
}

export default LiveChat;
