import React from 'react';
import Icons from './Icons';
import {withTranslation} from "react-i18next";
import cartStore from 'tunnel-commande/src/components/cart/CartStore';
import { isEqual } from '../utils';

export class CartIcon extends React.Component<any, any> {
  private lng: string ;
  constructor(props: any) {
    super(props);

    this.lng = this.props.i18n.language;
    this.handleCartStoreChange = this.handleCartStoreChange.bind(this);

    this.state = {
      cart: cartStore.getCart()
    };
  }

  shouldComponentUpdate(_nextProps: Readonly<any>, nextState: Readonly<any>, _nextContext: any): boolean {
    if(!isEqual(nextState.cart, this.state.cart) || !isEqual(_nextProps.smallDevice, this.props.smallDevice) ) {
      return true;
    }

    return false;
  }

  componentDidMount(): void {
    this.lng = this.props.i18n.language;
    cartStore.addListener('change', this.handleCartStoreChange);
  }

  componentDidUpdate(NextProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if(NextProps.i18n.language !== this.lng) {
      this.lng = NextProps.i18n.language;
    }
  }

  componentWillUnmount(): void {
    cartStore.removeListener('change', this.handleCartStoreChange);
  }

  private handleCartStoreChange() {
    this.setState({
      cart: cartStore.getCart()
    });
  }

  render() {
    const color = this.props.smallDevice ? 'white' : 'darkGrey' ;
    const size = this.props.smallDevice ? 25 : 25 ;
    const result = this.props.t('result');
    const results = this.props.t('results');
    let str_qty = 0;

    if(this.state.cart && this.state.cart.items) {
      str_qty = this.state.cart.items.reduce((qte: any, elt: any) => {
        return qte + parseInt(elt.quantite)
      }, 0);
    }

    let str_results = str_qty + ' ' +  results;
    if(str_qty<2) {
      str_results = str_qty + ' ' +  result;
    }

    return (
      <a href={`/${this.lng}/cart`} title={this.props.t('see_my_cart')} >
        <span className="cursor-pointer blocMenuPanier p-1 py-2 d-flex align-items-center">
          <span className={"position-relative"}>
          <Icons icon="panier" size={size} className={`shortIconMenu color mr-2 ml-2 `+color}/>
            {(this.state.cart && this.state.cart.items && this.state.cart.items.length > 0 ) &&
            <span className="nbProductsCart position-absolute font10 badge badge-pill cursor-pointer white ">
               {str_qty}
            </span>
            }
          </span>
          {(this.state.cart && this.state.cart.items && this.state.cart.items.length > 0 && !this.props.mobileMenu && !this.props.smallDevice) &&
            <div className={'d-flex flex-column justify-content-start ml-2'}>
            <span className={'badge badge-pill text-uppercase cursor-pointer text-left'}>
               {this.props.t('cart')}
            </span>
              <span className={'badge badge-pill cursor-pointer text-left'}>
                {str_results}
              </span>
            </div>
          }
          {(this.props.mobileMenu && this.props.smallDevice) &&
          <>{this.props.t('cart')}</>
          }
        </span>
      </a>
    );
  }
}

export default withTranslation('common')(CartIcon);
