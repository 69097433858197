import React from 'react';
import IconsSocial from './IconsSocial';
import {withTranslation} from 'react-i18next';

class Social extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      sizeDefault: 30,
      icInsta: 'instagram-white'
    };
  }

  static getInitialProps({query}: {query: any}) {
    return {token: query.token, namespacesRequired: ['common']};
  }

  changeIcon(nomIc: string) {
    if(this.state.icInsta !== nomIc) {
      this.setState({icInsta: nomIc});
    }
  }

  render() {
    return (
      <>
        <div className={'container-fluid d-flex py-2 px-0 container-fluid-dt socialFlags flex-wrap'}>
          <a rel="noopener" href="https://www.youtube.com/channel/UClCDzYi9yehwf6avVjNl_eg" target="_blank" title={"Youtube"} className={'mr-4 mb-4'}>
            <IconsSocial icon="youtube" size={this.state.sizeDefault} className={'white'}/>
          </a>
          <a rel="noopener" href="https://www.facebook.com/Ducktransferfr" target="_blank" title={"Facebook"} className={'mr-4 mb-4'}>
            <IconsSocial icon="facebook" size={this.state.sizeDefault} className={''}/>
          </a>
          <a rel="noopener" href="https://www.instagram.com/ducktransferfr/"  title={"Instagram"} onMouseEnter={() => this.changeIcon('instagram')} onMouseLeave={() => this.changeIcon('instagram-white')} target="_blank" className={'mr-4 mb-4'}>
            <IconsSocial icon={this.state.icInsta} size={this.state.sizeDefault} className={''}/>
          </a>
          <a rel="noopener" href="https://www.linkedin.com/company/ducktransfer" target="_blank" title={"Linkedin"} className={'mr-4 mb-4'}>
            <IconsSocial icon="linkedin" size={this.state.sizeDefault} className={''}/>
          </a>
          <a rel="noopener" href="https://x.com/DuckTransfer" target="_blank" title={"X"}  className={'mb-4'}>
            <IconsSocial icon="x" size={this.state.sizeDefault} className={''}/>
          </a>
        </div>
      </>
    );
  }
}
export default withTranslation('common')(Social);
