import {isServerSide} from 'tunnel-commande/src/utils';
import React from "react";
import Icons from './components/Icons';
import {Organization} from 'schema-dts';
import { JsonLd } from "react-schemaorg";

/**
 * Verifie si un element de la page est visible
 * @param elt
 */
export function elementIsVisible(elt: any) : boolean {
  if(isServerSide()) {
    return false;
  }

  const eltTop = elt.getBoundingClientRect().top;
  const eltBottom = elt.getBoundingClientRect().bottom;

  return (eltTop <= window.innerHeight || eltBottom <= window.innerHeight);
}

/**
 * Verifie si un object possède une propriété
 * @param object
 * @param key
 */
export function has(object: any, key: any) : boolean {
  return (!empty(object) && Object.prototype.hasOwnProperty.call(object, key));
}

/**
 * Verifie si une variable est vide, meme règle que le empty de PHP
 * @param value
 */
export function empty(value: any) : boolean {
  if(value === null || typeof value === 'undefined') {
    return true;
  }
  if((typeof value === 'string' || Array.isArray(value)) && value.length === 0) {
    return true;
  }
  if(typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }
  if(value === false || value === 0 || value === '0') {
    return true;
  }

  return false;
}

/**
 * Sert a comparer un tableau ou un object
 * @param value
 * @param other
 * @param exclude
 */
export function isEqual(value: any, other: any, exclude: any = []) : boolean {
  const type = Object.prototype.toString.call(value);

  if (type !== Object.prototype.toString.call(other)) return false;

  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  const compare = function (item1: any, item2: any) {
    const itemType = Object.prototype.toString.call(item1);

    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) {
          return false;
        }
      }
    }
  };

  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key) && exclude.indexOf(key) == -1) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  return true;
}

export const  textTruncate = (str: string, lengthString: number = 100, ending: string = '...') => {
  if (str.length > lengthString) {
    return str.substring(0, lengthString - ending.length) + ending;
  }
  return str;
}

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const generateBaseUrl = (env: string): string => {
  const prefix = getPrefixFromEnv(env);
  const port = getPortByEnv(env);

  return `https://${prefix}.ducktransfer.com${port}`;
}

export const getPortByEnv = (env: string): string => {
  if(env === 'development') {
    return ':3001';
  }

  return '';
}

export const getPrefixFromEnv = (env: string): string => {
  if(env === 'development') {
    return 'devvm';
  } else if(env === 'preprod') {
    return 'preprod';
  }

  return 'www';
}

export const renderLink = (route: string, text: string, as: string = "", icon: string = "arrow_right") => {
  const defineIconeSize = () => {
    if (icon === 'arrow_right') {
      return 25;
    }
    if (icon === 'home') {
      return 18;
    }
    return 10;
  };

  const defineIcon = () => {
    if (icon !== 'none') {
      return <Icons icon={icon} size={defineIconeSize()} className="mr-1"/>;
    }
  }

  return (
    <a href={route} className="cursor-pointer d-flex align-items-center d-md-block">
      {defineIcon()}
      {text}
    </a>
  );
};

export function schemaDT() {
  return (
    <JsonLd<Organization>
      item={{
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "DuckTransfer",
        url: "https://www.ducktransfer.com",
        sameAs: [
          "https://www.twitter.com/DuckTransfer",
          "https://www.instagram.com/ducktransferfr",
          "https://www.facebook.com/Ducktransferfr",
          "https://www.linkedin.com/company/ducktransfer"
        ],
        address: {
          "@type": "PostalAddress",
          "addressLocality": "Vire-Normandie, France",
          "streetAddress": "Z.A. La Papillonnière",
          "postalCode": "F-14500"
        }
      }}
    />
  );
}


export function getCategoryId(category: any) : number {
  const queryCategoryArr: string[] = category.split('-');
  return parseInt(queryCategoryArr[queryCategoryArr.length - 1]);
}
