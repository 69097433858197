import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";

type BandeauProps = {
  env: string
} & WithTranslation;

class Bandeau extends React.Component<BandeauProps, any> {
  constructor(props: BandeauProps) {
    super(props);

    this.state = {
      open: true
    };
  }

  shouldComponentUpdate(_nextProps: Readonly<any>, nextState: Readonly<any>, _nextContext: any): boolean {
    return (this.state.open !== nextState.open);
  }

  render() {
    if(!this.state.open) {
      return false;
    }

    const env = this.props.env;
    if(env === 'production') {
      return false;
    }

    const colors: any = {
      'development': 'rgba(225,180,0,0.8)',
      'preprod': 'rgba(220,0,36,0.8)'
    };

    const style: any = {
      width: '100%',
      lineHeight: '0.5em',
      textAlign: 'center',
      color: '#fff',
      fontFamily: 'arial, sans-serif',
      padding: '10px 0',
      background: colors[env],
      position: 'fixed',
      top: '0',
      zIndex: 10000000,
      fontSize: '13px'
    };

    return (
      <span style={style}>
        <span>SERVEUR {env.toUpperCase()}</span>
        <button style={{padding: '4px 15px', marginLeft: '30px'}}
                onClick={() => {this.setState({open: false})}}>Fermer</button>
      </span>
    );
  }
}

export default withTranslation()(Bandeau);
