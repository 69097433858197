/* eslint-disable */

import React from 'react';

declare var window: any;
declare var UET: any;

class Bing extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {};
    }

    shouldComponentUpdate(_nextProps: Readonly<any>, _nextState: Readonly<any>, _nextContext: any): boolean {
        return false;
    }

    componentDidMount(): void {
        var that = this;
        (function (w, d, t, r, u) {
            var f: any, n: any, i: any;
            w[u] = w[u] || [], f = function () {
                var o: any = {ti: that.props.bingid};
                o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad")
            }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
                var s = this.readyState;
                s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)
            }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
        })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
    }

    render() {
        return false;
    }
}

export default Bing;
