/* eslint-disable */

import React from 'react';

declare var window: any;
declare var ga: any;

class GTag extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(_nextProps: Readonly<any>, _nextState: Readonly<any>, _nextContext: any): boolean {
    return false;
  }

  componentDidMount(): void {
   
  }

  render() {
    return (
      <>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${this.props.gtagId}`}></script>
        <script dangerouslySetInnerHTML={{ __html: `
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', 'G-VXKS9NFC5C');
          gtag('config', '${this.props.gtagId}', {'allow_enhanced_conversions':true});

        ` }} />

        <script dangerouslySetInnerHTML={ { __html: ``}} />
      </>
    )
  }
}

export default GTag;
