import * as React from 'react';
import IconBase from 'react-icon-base';

export default class IconsSetMenu extends React.Component<any, any> {
  renderGraphic() {
    switch (this.props.icon) {
      case 'ducktransfer':
        return (
          <IconBase viewBox="0 0 176 27" {...this.props}>
            <g>
              <path fill="#E92328" d="M41.628,18.849c-0.025,0.276-0.059,0.467-0.1,0.571c-0.042,0.104-0.134,0.173-0.276,0.207
	c-0.711,0.2-1.453,0.403-2.227,0.608c-0.774,0.205-1.524,0.475-2.252,0.809c-0.025,0.009-0.063,0.013-0.113,0.013
	c-0.067,0-0.106-0.017-0.119-0.05c-0.013-0.034-0.019-0.071-0.019-0.113c0-0.017,0.001-0.046,0.006-0.088s0.015-0.119,0.031-0.231
	c0.017-0.114,0.04-0.27,0.069-0.471c0.029-0.201,0.069-0.468,0.119-0.803c-0.937,0.61-1.767,1.056-2.49,1.336
	s-1.365,0.42-1.925,0.42c-1.396,0-2.415-0.337-3.055-1.01c-0.639-0.673-0.959-1.679-0.959-3.018c0-0.242,0.004-0.49,0.012-0.745
	c0.008-0.256,0.033-0.517,0.075-0.785c0.092-0.986,0.171-1.833,0.238-2.54c0.067-0.706,0.121-1.307,0.163-1.8
	c0.042-0.493,0.077-0.896,0.106-1.21c0.03-0.313,0.054-0.575,0.075-0.784s0.038-0.383,0.05-0.521
	c0.013-0.139,0.027-0.274,0.044-0.408c0.034-0.267,0.065-0.455,0.094-0.564c0.029-0.108,0.123-0.18,0.282-0.213
	c0.393-0.075,0.767-0.145,1.123-0.207s0.707-0.13,1.054-0.201c0.347-0.07,0.7-0.147,1.06-0.231s0.74-0.184,1.142-0.301
	c0.017,0,0.033-0.002,0.05-0.007c0.017-0.004,0.033-0.006,0.05-0.006c0.101,0,0.151,0.033,0.151,0.1v0.025
	c-0.034,0.284-0.063,0.552-0.088,0.803s-0.05,0.509-0.075,0.771c-0.025,0.264-0.052,0.547-0.082,0.847
	c-0.03,0.302-0.065,0.651-0.106,1.048c-0.042,0.396-0.092,0.855-0.151,1.373c-0.059,0.519-0.13,1.121-0.213,1.807
	c-0.051,0.502-0.101,1.002-0.151,1.499s-0.075,0.959-0.075,1.386c0,0.234,0.017,0.45,0.05,0.646
	c0.034,0.196,0.094,0.364,0.182,0.501c0.088,0.139,0.203,0.245,0.345,0.32c0.142,0.075,0.326,0.113,0.551,0.113
	c0.176,0,0.415-0.04,0.716-0.119c0.301-0.079,0.602-0.218,0.903-0.414c0.301-0.197,0.566-0.455,0.796-0.777s0.362-0.729,0.396-1.224
	c0.058-0.827,0.11-1.52,0.157-2.075c0.045-0.556,0.085-1.024,0.119-1.406c0.033-0.38,0.063-0.699,0.087-0.959
	c0.025-0.259,0.048-0.508,0.069-0.746s0.04-0.493,0.057-0.766c0.017-0.271,0.033-0.612,0.05-1.022
	c0.025-0.276,0.057-0.466,0.094-0.57s0.132-0.174,0.282-0.207c0.761-0.151,1.539-0.303,2.333-0.457
	c0.794-0.155,1.572-0.354,2.333-0.597c0.067,0,0.107,0.01,0.119,0.031c0.013,0.021,0.019,0.052,0.019,0.094L41.628,18.849z"/>
              <path fill="#E92328" d="M44.175,14.283c0-1.096,0.203-2.122,0.608-3.08c0.405-0.957,0.966-1.792,1.681-2.502
	c0.715-0.711,1.56-1.271,2.534-1.682c0.974-0.409,2.035-0.614,3.18-0.614c0.778,0,1.442,0.063,1.995,0.188
	c0.552,0.125,1.003,0.266,1.354,0.42c0.351,0.155,0.608,0.297,0.771,0.427c0.163,0.129,0.245,0.198,0.245,0.207
	c0,0.05-0.019,0.189-0.057,0.42c-0.037,0.229-0.079,0.497-0.125,0.803c-0.046,0.306-0.088,0.619-0.125,0.94
	c-0.038,0.322-0.056,0.601-0.056,0.835c0,0.042-0.013,0.087-0.038,0.137c-0.025,0.052-0.092,0.076-0.2,0.076
	c-0.101,0-0.247-0.036-0.439-0.106c-0.192-0.071-0.429-0.148-0.709-0.232c-0.28-0.083-0.602-0.161-0.966-0.231
	c-0.363-0.071-0.768-0.107-1.21-0.107c-0.552,0-1.05,0.099-1.493,0.295c-0.443,0.196-0.824,0.469-1.141,0.815
	c-0.318,0.347-0.563,0.759-0.734,1.236c-0.171,0.477-0.257,0.991-0.257,1.542c0,0.577,0.089,1.068,0.27,1.475
	c0.18,0.405,0.42,0.736,0.722,0.99c0.301,0.256,0.648,0.441,1.041,0.559c0.393,0.117,0.807,0.176,1.242,0.176
	c0.502,0,1.008-0.069,1.518-0.207s0.991-0.332,1.443-0.583c0.016-0.009,0.05-0.013,0.1-0.013c0.042,0,0.073,0.021,0.094,0.063
	c0.021,0.041,0.031,0.121,0.031,0.238v0.972c0,0.381-0.002,0.745-0.007,1.092c-0.003,0.348-0.01,0.643-0.018,0.885
	c-0.009,0.242-0.017,0.363-0.025,0.363c-0.301,0.2-0.627,0.364-0.978,0.489c-0.352,0.125-0.713,0.227-1.085,0.302
	c-0.373,0.075-0.746,0.124-1.123,0.15c-0.376,0.024-0.74,0.037-1.091,0.037c-0.979,0-1.89-0.163-2.735-0.489
	s-1.578-0.786-2.201-1.38s-1.114-1.307-1.474-2.139C44.354,16.217,44.175,15.295,44.175,14.283"/>
              <path fill="#E92328" d="M64.396,1.362l-0.451,4.354c-0.059,0.585-0.12,1.179-0.183,1.781c-0.063,0.602-0.124,1.179-0.182,1.731
	c-0.059,0.551-0.113,1.062-0.163,1.529c-0.05,0.469-0.092,0.854-0.125,1.154l0.866-0.953l1.405-1.644
	c0.176-0.2,0.347-0.401,0.514-0.602c0.167-0.201,0.318-0.379,0.451-0.534c0.134-0.154,0.243-0.283,0.326-0.388
	c0.084-0.105,0.134-0.166,0.15-0.183l5.043-0.351c0.024-0.009,0.052-0.013,0.082-0.013h0.082c0.142,0,0.213,0.037,0.213,0.112
	c0,0.06-0.088,0.188-0.264,0.389l-3.926,4.392c-0.176,0.191-0.383,0.415-0.621,0.67c-0.238,0.256-0.504,0.538-0.796,0.848
	l1.832,2.182l3.161,3.4c0.024,0.041,0.063,0.073,0.112,0.094c0.051,0.021,0.099,0.04,0.145,0.057s0.088,0.035,0.125,0.057
	c0.038,0.021,0.056,0.051,0.056,0.094c0,0.032-0.006,0.059-0.019,0.075s-0.052,0.05-0.119,0.101
	c-0.075,0.059-0.207,0.142-0.395,0.251c-0.188,0.108-0.408,0.229-0.659,0.363s-0.519,0.27-0.803,0.408
	c-0.284,0.138-0.561,0.264-0.828,0.376c-0.268,0.113-0.514,0.205-0.741,0.276c-0.226,0.07-0.401,0.106-0.526,0.106
	c-0.101,0-0.199-0.025-0.295-0.075c-0.097-0.051-0.203-0.143-0.32-0.276c-0.159-0.184-0.357-0.425-0.596-0.722
	c-0.238-0.297-0.496-0.622-0.771-0.978c-0.276-0.356-0.558-0.724-0.847-1.104c-0.289-0.38-0.565-0.744-0.828-1.092
	c-0.263-0.347-0.501-0.66-0.715-0.94s-0.378-0.504-0.496-0.671c-0.042,0.042-0.084,0.073-0.125,0.094
	c-0.042,0.021-0.082,0.048-0.119,0.082c-0.038,0.033-0.069,0.073-0.094,0.119s-0.038,0.11-0.038,0.194
	c0,0.033-0.009,0.128-0.025,0.282c-0.017,0.155-0.036,0.339-0.057,0.552c-0.021,0.213-0.044,0.441-0.069,0.684
	c-0.025,0.243-0.048,0.471-0.069,0.684c-0.021,0.214-0.04,0.397-0.057,0.552c-0.017,0.155-0.025,0.249-0.025,0.283
	c0,0.058,0.001,0.106,0.006,0.144c0.003,0.038,0.006,0.073,0.006,0.107c0,0.092-0.032,0.16-0.094,0.206
	c-0.063,0.047-0.215,0.103-0.458,0.17l-3.286,1.066c-0.093,0.033-0.214,0.075-0.364,0.125c-0.151,0.051-0.297,0.075-0.439,0.075
	c-0.117,0-0.22-0.029-0.307-0.088c-0.088-0.059-0.132-0.167-0.132-0.326l1.581-18.075c0-0.042-0.004-0.082-0.013-0.12
	c-0.009-0.037-0.012-0.077-0.012-0.119c0-0.075,0.031-0.145,0.094-0.207s0.207-0.123,0.433-0.182l3.524-0.84
	c0.092-0.018,0.213-0.055,0.364-0.113c0.151-0.058,0.297-0.088,0.439-0.088c0.108,0,0.211,0.025,0.307,0.075
	c0.096,0.051,0.144,0.146,0.144,0.289c0,0.017-0.002,0.033-0.006,0.05C64.398,1.329,64.396,1.347,64.396,1.362"/>
              <path d="M75.182,20.806c0.042-0.61,0.083-1.2,0.125-1.769c0.041-0.568,0.079-1.121,0.113-1.656l0.452-5.92
	c0.05-0.452,0.091-0.864,0.125-1.236s0.061-0.735,0.082-1.091c0.021-0.355,0.042-0.716,0.063-1.079
	c0.021-0.363,0.044-0.767,0.069-1.211c-0.276-0.008-0.567-0.012-0.872-0.012h-0.96h-2.42c-0.092,0-0.149-0.036-0.17-0.106
	c-0.021-0.071-0.031-0.137-0.031-0.195V6.442l1.38-3.625l-0.013-0.05c0-0.042,0.027-0.077,0.081-0.106
	c0.055-0.029,0.178-0.053,0.371-0.069c1.154-0.067,2.273-0.132,3.355-0.194c1.083-0.063,2.154-0.119,3.211-0.169
	s2.115-0.101,3.174-0.15c1.058-0.051,2.146-0.097,3.268-0.139c0.1,0,0.207,0.013,0.32,0.038c0.112,0.024,0.168,0.117,0.168,0.276
	c0,0.017-0.004,0.032-0.012,0.05c-0.008,0.017-0.013,0.033-0.013,0.05c-0.133,0.343-0.257,0.669-0.37,0.979
	c-0.112,0.31-0.224,0.614-0.333,0.915c-0.108,0.302-0.219,0.607-0.332,0.917c-0.113,0.309-0.237,0.635-0.37,0.978
	c-0.008,0.009-0.025,0.049-0.05,0.119c-0.025,0.071-0.063,0.15-0.112,0.238c-0.051,0.088-0.118,0.17-0.201,0.245
	s-0.188,0.117-0.313,0.125c-0.042-0.008-0.083-0.013-0.126-0.013h-0.138h-3.349c-0.042,0.427-0.082,0.879-0.119,1.355
	s-0.082,0.94-0.131,1.393l-0.917,9.797c-0.008,0.075-0.023,0.15-0.043,0.226s-0.077,0.13-0.169,0.163l-3.55,1.279
	c-0.092,0.033-0.213,0.075-0.364,0.125c-0.15,0.051-0.297,0.076-0.439,0.076c-0.117,0-0.219-0.03-0.307-0.088
	c-0.088-0.06-0.131-0.168-0.131-0.326V20.806z"/>
              <path d="M95.643,10.193c-0.175-0.017-0.371-0.035-0.589-0.057c-0.218-0.021-0.448-0.031-0.69-0.031
	c-0.367,0-0.741,0.031-1.122,0.095c-0.381,0.063-0.734,0.187-1.061,0.37s-0.606,0.437-0.84,0.759
	c-0.234,0.322-0.385,0.738-0.452,1.248c-0.059,0.619-0.106,1.127-0.144,1.523c-0.038,0.397-0.065,0.725-0.082,0.979
	c-0.017,0.256-0.031,0.46-0.044,0.615c-0.013,0.154-0.023,0.299-0.031,0.433c0,0.034-0.007,0.126-0.019,0.276
	c-0.013,0.15-0.032,0.326-0.057,0.526c-0.025,0.2-0.048,0.418-0.069,0.652c-0.021,0.234-0.044,0.451-0.068,0.652
	c-0.025,0.2-0.044,0.376-0.057,0.526s-0.02,0.242-0.02,0.276c0,0.067,0.003,0.123,0.007,0.17c0.004,0.045,0.006,0.09,0.006,0.132
	c0,0.066-0.031,0.123-0.094,0.169s-0.203,0.099-0.42,0.156l-3.325,1.192c-0.093,0.033-0.213,0.075-0.364,0.125
	c-0.15,0.051-0.297,0.075-0.438,0.075c-0.118,0-0.22-0.029-0.307-0.088c-0.089-0.059-0.133-0.159-0.133-0.302L86.31,8.236
	c0.025-0.275,0.057-0.466,0.094-0.57s0.131-0.173,0.282-0.207c0.761-0.15,1.547-0.303,2.359-0.458
	c0.811-0.154,1.598-0.353,2.358-0.596c0.066,0,0.106,0.011,0.119,0.031c0.013,0.021,0.019,0.052,0.019,0.094
	c0,0.018-0.002,0.043-0.006,0.075c-0.005,0.034-0.018,0.109-0.038,0.226c-0.021,0.118-0.05,0.295-0.088,0.534
	c-0.037,0.238-0.09,0.57-0.156,0.997c0.384-0.301,0.809-0.577,1.272-0.828c0.465-0.251,0.931-0.466,1.399-0.646
	c0.468-0.18,0.919-0.322,1.354-0.427c0.435-0.104,0.815-0.157,1.142-0.157L95.643,10.193z"/>
              <path d="M105.565,13.154c0.083-0.477,0.14-0.927,0.169-1.35c0.029-0.422,0.053-0.809,0.069-1.159
	c-0.134-0.102-0.278-0.205-0.433-0.314c-0.155-0.108-0.322-0.211-0.502-0.307c-0.18-0.097-0.368-0.174-0.564-0.232
	s-0.403-0.088-0.621-0.088c-0.451,0-0.83,0.146-1.135,0.439c-0.306,0.293-0.553,0.652-0.74,1.078
	c-0.188,0.427-0.322,0.878-0.401,1.355c-0.08,0.477-0.119,0.898-0.119,1.267c0,1.321,0.173,2.29,0.521,2.904
	c0.347,0.614,0.83,0.922,1.448,0.922c0.251,0,0.531-0.071,0.841-0.214c0.31-0.142,0.623-0.339,0.94-0.589L105.565,13.154z
	 M109.316,17.883c-0.009,0.033-0.013,0.065-0.013,0.095v0.081c0,0.209,0.033,0.383,0.101,0.521c0.066,0.138,0.14,0.251,0.219,0.339
	c0.08,0.088,0.152,0.158,0.22,0.214c0.066,0.054,0.101,0.102,0.101,0.144c0,0.117-0.126,0.226-0.376,0.326l-3.375,1.442
	c-0.009,0.009-0.037,0.013-0.088,0.013c-0.067,0-0.134-0.059-0.2-0.176c-0.067-0.117-0.132-0.265-0.194-0.445
	c-0.063-0.18-0.121-0.371-0.176-0.577c-0.055-0.205-0.103-0.395-0.145-0.57c-0.526,0.61-1.096,1.059-1.706,1.342
	c-0.61,0.284-1.217,0.427-1.819,0.427c-0.702,0-1.388-0.154-2.057-0.465c-0.669-0.309-1.263-0.75-1.781-1.322
	c-0.519-0.573-0.935-1.266-1.248-2.076c-0.313-0.812-0.471-1.723-0.471-2.735c0-1.195,0.174-2.287,0.521-3.273
	c0.348-0.987,0.817-1.836,1.411-2.547c0.595-0.71,1.286-1.261,2.076-1.649c0.791-0.389,1.629-0.583,2.516-0.583
	c0.627,0,1.221,0.128,1.781,0.382c0.56,0.256,1.091,0.584,1.593,0.985c0.051-0.251,0.079-0.452,0.088-0.603
	c0.008-0.15,0.027-0.268,0.057-0.351s0.086-0.141,0.169-0.17c0.084-0.028,0.222-0.052,0.414-0.068
	c0.603-0.084,1.202-0.161,1.8-0.232s1.224-0.123,1.876-0.156L109.316,17.883z"/>
              <path d="M122.575,20.856c-0.092,0.024-0.213,0.064-0.363,0.119c-0.151,0.054-0.298,0.081-0.439,0.081
	c-0.117,0-0.22-0.029-0.308-0.088s-0.132-0.159-0.132-0.301v-0.075c0.05-0.452,0.092-0.834,0.126-1.147
	c0.033-0.313,0.064-0.599,0.094-0.854s0.054-0.51,0.075-0.765c0.021-0.256,0.048-0.553,0.081-0.892
	c0.033-0.338,0.073-0.739,0.119-1.204c0.047-0.464,0.104-1.043,0.17-1.737c0.042-0.427,0.08-0.86,0.112-1.305
	c0.034-0.443,0.051-0.853,0.051-1.229c0-0.251-0.02-0.483-0.057-0.696c-0.038-0.213-0.104-0.397-0.201-0.552
	c-0.096-0.155-0.228-0.274-0.395-0.358c-0.167-0.083-0.381-0.125-0.64-0.125c-0.192,0-0.458,0.042-0.797,0.125
	c-0.339,0.084-0.676,0.222-1.01,0.414c-0.335,0.193-0.631,0.446-0.891,0.759c-0.259,0.314-0.406,0.701-0.438,1.161
	c-0.05,0.618-0.097,1.147-0.139,1.586c-0.042,0.439-0.079,0.812-0.113,1.117c-0.032,0.305-0.061,0.554-0.081,0.746
	s-0.035,0.355-0.044,0.489c-0.009,0.034-0.021,0.126-0.038,0.276c-0.016,0.15-0.035,0.33-0.056,0.539
	c-0.021,0.209-0.042,0.431-0.063,0.665c-0.021,0.234-0.041,0.455-0.063,0.664c-0.021,0.21-0.037,0.389-0.05,0.539
	c-0.013,0.151-0.019,0.243-0.019,0.277c0,0.066,0.002,0.123,0.006,0.169s0.006,0.09,0.006,0.132c0,0.066-0.031,0.123-0.094,0.169
	s-0.202,0.099-0.42,0.157l-3.324,1.142c-0.093,0.033-0.213,0.075-0.364,0.125c-0.15,0.05-0.297,0.075-0.438,0.075
	c-0.117,0-0.22-0.029-0.308-0.088S112,20.81,112,20.668l1.142-12.82c0.025-0.276,0.057-0.466,0.095-0.57
	c0.037-0.104,0.131-0.174,0.282-0.207c0.761-0.151,1.543-0.304,2.346-0.458s1.585-0.354,2.346-0.596c0.066,0,0.106,0.01,0.119,0.031
	s0.019,0.052,0.019,0.094c0,0.018-0.002,0.042-0.006,0.075c-0.005,0.033-0.018,0.109-0.038,0.226
	c-0.021,0.117-0.05,0.295-0.088,0.533c-0.037,0.239-0.09,0.571-0.156,0.998c0.576-0.335,1.118-0.604,1.624-0.81
	s0.983-0.363,1.43-0.477c0.447-0.113,0.868-0.188,1.262-0.226c0.393-0.038,0.76-0.057,1.104-0.057c1.179,0,2.036,0.357,2.571,1.072
	s0.803,1.788,0.803,3.218c0,0.2-0.004,0.407-0.013,0.621c-0.008,0.213-0.025,0.433-0.05,0.658l-0.577,5.395
	c-0.042,0.352-0.067,0.675-0.075,0.972c-0.009,0.297-0.013,0.642-0.013,1.035c0,0.066,0.002,0.123,0.007,0.169
	c0.004,0.046,0.006,0.086,0.006,0.119c0,0.075-0.031,0.134-0.095,0.176c-0.063,0.042-0.202,0.097-0.42,0.163L122.575,20.856z"/>
              <path d="M128.81,16.942c0-0.008-0.002-0.015-0.007-0.02c-0.004-0.004-0.006-0.01-0.006-0.019c0-0.107,0.059-0.163,0.176-0.163
	c0.075,0,0.143,0.029,0.2,0.088c0.334,0.318,0.718,0.577,1.148,0.778c0.43,0.2,0.896,0.301,1.398,0.301
	c0.134,0,0.288-0.015,0.464-0.044s0.343-0.077,0.502-0.145c0.159-0.066,0.29-0.156,0.396-0.27c0.104-0.112,0.156-0.253,0.156-0.42
	c0-0.185-0.046-0.354-0.138-0.509c-0.093-0.154-0.214-0.281-0.364-0.382c-0.084-0.059-0.23-0.161-0.439-0.308
	s-0.432-0.303-0.67-0.471c-0.239-0.167-0.465-0.326-0.678-0.477s-0.362-0.259-0.445-0.326c-0.376-0.284-0.675-0.572-0.896-0.865
	s-0.392-0.596-0.508-0.909c-0.118-0.314-0.193-0.643-0.227-0.984c-0.033-0.344-0.05-0.707-0.05-1.092
	c0-0.586,0.154-1.138,0.464-1.656s0.717-0.975,1.223-1.367c0.506-0.394,1.081-0.704,1.725-0.935c0.645-0.229,1.301-0.345,1.97-0.345
	c0.552,0,1.096,0.083,1.631,0.251c0.535,0.167,1.032,0.451,1.493,0.853c0.041,0.527,0.07,1.046,0.087,1.556
	c0.018,0.51,0.043,1.045,0.075,1.605c0,0.109-0.024,0.183-0.075,0.22c-0.05,0.038-0.108,0.057-0.175,0.057
	c-0.06,0-0.109-0.021-0.15-0.063c-0.193-0.184-0.39-0.365-0.59-0.545c-0.201-0.18-0.412-0.339-0.634-0.477s-0.458-0.251-0.709-0.339
	s-0.519-0.132-0.803-0.132c-0.176,0-0.351,0.015-0.526,0.044s-0.335,0.084-0.477,0.163c-0.143,0.079-0.257,0.187-0.346,0.32
	c-0.087,0.134-0.132,0.306-0.132,0.514c0,0.243,0.03,0.451,0.088,0.627c0.059,0.176,0.196,0.348,0.414,0.515l2.333,1.581
	c0.427,0.293,0.771,0.579,1.035,0.858c0.264,0.281,0.47,0.559,0.621,0.835c0.15,0.275,0.253,0.55,0.308,0.821
	c0.055,0.272,0.081,0.542,0.081,0.81c0,0.753-0.173,1.415-0.521,1.987c-0.347,0.573-0.796,1.051-1.349,1.431
	c-0.552,0.381-1.177,0.669-1.875,0.865s-1.398,0.295-2.102,0.295c-0.534,0-1.052-0.057-1.549-0.169
	c-0.497-0.113-0.959-0.287-1.386-0.521L128.81,16.942z"/>
              <path d="M140.839,20.593c0.008-0.093,0.027-0.291,0.057-0.597c0.029-0.305,0.064-0.691,0.106-1.159
	c0.042-0.469,0.09-1.006,0.145-1.612c0.054-0.606,0.114-1.258,0.182-1.957c0.066-0.698,0.136-1.428,0.207-2.188
	c0.071-0.762,0.145-1.531,0.22-2.309h-1.669c-0.451,0-0.777-0.019-0.979-0.057c-0.2-0.037-0.301-0.114-0.301-0.232
	c0-0.032,0.042-0.123,0.126-0.269c0.083-0.147,0.189-0.322,0.319-0.527s0.27-0.425,0.42-0.658c0.151-0.234,0.295-0.454,0.434-0.659
	c0.138-0.204,0.257-0.382,0.357-0.533c0.1-0.15,0.163-0.242,0.188-0.276h1.53l0.251-1.993c0.117-1.055,0.328-1.94,0.634-2.66
	c0.306-0.719,0.686-1.301,1.142-1.743c0.455-0.443,0.977-0.761,1.562-0.953c0.586-0.192,1.213-0.289,1.882-0.289
	s1.365,0.082,2.089,0.245c0.723,0.163,1.452,0.387,2.189,0.671c0.157,0.067,0.277,0.123,0.356,0.17
	c0.079,0.045,0.119,0.105,0.119,0.181c0,0.067-0.029,0.155-0.088,0.264c-0.268,0.519-0.466,0.937-0.596,1.255
	c-0.13,0.317-0.232,0.568-0.308,0.752c-0.075,0.185-0.138,0.308-0.188,0.371c-0.051,0.063-0.126,0.094-0.227,0.094
	c-0.05,0-0.103-0.013-0.156-0.038c-0.055-0.025-0.119-0.055-0.194-0.088c-0.293-0.108-0.588-0.207-0.885-0.295
	s-0.579-0.132-0.847-0.132c-0.376,0-0.7,0.118-0.973,0.352c-0.271,0.234-0.487,0.678-0.646,1.329
	c-0.092,0.377-0.167,0.783-0.226,1.218s-0.109,0.865-0.15,1.291h3.273c0.067,0,0.132,0.023,0.195,0.069
	c0.063,0.046,0.093,0.124,0.093,0.232c0,0.042-0.049,0.192-0.15,0.451c-0.1,0.26-0.242,0.599-0.426,1.017l-0.452,1.016
	c-0.058,0.125-0.119,0.218-0.182,0.276s-0.19,0.096-0.383,0.113h-2.17c-0.092,0.869-0.18,1.73-0.264,2.583
	c-0.083,0.854-0.163,1.654-0.238,2.403c-0.075,0.748-0.143,1.413-0.2,1.994c-0.059,0.581-0.105,1.03-0.138,1.349
	c-0.009,0.024-0.013,0.07-0.013,0.138c0,0.033,0.002,0.063,0.006,0.088c0.004,0.024,0.007,0.046,0.007,0.063
	c0,0.075-0.027,0.136-0.082,0.183c-0.055,0.045-0.194,0.098-0.42,0.156l-3.299,1.166c-0.093,0.034-0.214,0.075-0.364,0.126
	c-0.151,0.05-0.297,0.075-0.438,0.075c-0.143,0-0.251-0.033-0.326-0.101C140.876,20.89,140.839,20.768,140.839,20.593"/>
              <path d="M157.962,9.742c-0.344,0-0.659,0.073-0.947,0.219c-0.289,0.147-0.552,0.355-0.79,0.628
	c-0.239,0.271-0.445,0.598-0.621,0.979s-0.318,0.801-0.427,1.261c0.861-0.151,1.576-0.31,2.146-0.477
	c0.568-0.168,1.023-0.337,1.367-0.509c0.342-0.171,0.584-0.343,0.728-0.514c0.142-0.172,0.213-0.337,0.213-0.496
	c0-0.15-0.057-0.292-0.169-0.427c-0.113-0.133-0.254-0.251-0.421-0.351c-0.167-0.101-0.35-0.178-0.546-0.232
	C158.298,9.77,158.12,9.742,157.962,9.742 M158.112,6.405c0.794,0,1.516,0.148,2.163,0.445c0.648,0.297,1.204,0.682,1.669,1.154
	c0.464,0.473,0.821,1.001,1.072,1.587c0.251,0.585,0.376,1.171,0.376,1.756c0,0.519-0.159,1.039-0.477,1.562
	c-0.317,0.523-0.764,0.993-1.336,1.411c-0.572,0.419-1.261,0.758-2.063,1.017s-1.686,0.389-2.646,0.389
	c-0.502,0-1.029-0.051-1.581-0.15c0.034,0.359,0.139,0.669,0.314,0.928c0.176,0.26,0.396,0.473,0.665,0.64
	c0.267,0.168,0.57,0.291,0.909,0.371c0.339,0.079,0.688,0.119,1.047,0.119c0.401,0,0.788-0.036,1.16-0.107s0.729-0.165,1.073-0.282
	c0.343-0.117,0.673-0.246,0.99-0.389c0.318-0.142,0.619-0.284,0.903-0.427c0.075-0.016,0.141-0.031,0.194-0.044
	c0.055-0.013,0.106-0.019,0.157-0.019c-0.009,0.268-0.013,0.522-0.013,0.766c0,0.242-0.002,0.535-0.006,0.878
	c-0.005,0.343,0.022,1.225,0.031,1.267c0.008,0.042,0.013,0.075,0.013,0.101c0,0.101-0.093,0.209-0.276,0.326
	s-0.425,0.238-0.722,0.363c-0.297,0.126-0.636,0.247-1.016,0.364c-0.381,0.117-0.768,0.224-1.16,0.319
	c-0.394,0.097-0.773,0.172-1.142,0.227c-0.368,0.054-0.689,0.081-0.966,0.081c-0.903,0-1.779-0.156-2.628-0.471
	c-0.85-0.313-1.602-0.763-2.258-1.348c-0.657-0.586-1.182-1.296-1.574-2.133c-0.394-0.836-0.59-1.777-0.59-2.822
	c0-1.096,0.188-2.122,0.564-3.08c0.376-0.957,0.905-1.792,1.587-2.502c0.682-0.712,1.494-1.271,2.439-1.682
	C155.934,6.61,156.975,6.405,158.112,6.405"/>
              <path d="M175.222,10.193c-0.176-0.017-0.372-0.035-0.589-0.057c-0.218-0.021-0.448-0.031-0.69-0.031
	c-0.368,0-0.742,0.031-1.123,0.095c-0.38,0.063-0.733,0.187-1.06,0.37s-0.606,0.437-0.84,0.759
	c-0.234,0.322-0.386,0.738-0.452,1.248c-0.059,0.619-0.106,1.127-0.145,1.523c-0.037,0.397-0.064,0.725-0.081,0.979
	c-0.017,0.256-0.031,0.46-0.044,0.615c-0.013,0.154-0.023,0.299-0.031,0.433c0,0.034-0.007,0.126-0.019,0.276
	c-0.013,0.15-0.032,0.326-0.057,0.526c-0.025,0.2-0.049,0.418-0.069,0.652s-0.044,0.451-0.068,0.652
	c-0.025,0.2-0.044,0.376-0.057,0.526s-0.02,0.242-0.02,0.276c0,0.067,0.002,0.123,0.007,0.17c0.004,0.045,0.006,0.09,0.006,0.132
	c0,0.066-0.031,0.123-0.094,0.169s-0.203,0.099-0.42,0.156l-3.324,1.192c-0.093,0.033-0.214,0.075-0.364,0.125
	c-0.15,0.051-0.297,0.075-0.438,0.075c-0.118,0-0.22-0.029-0.308-0.088s-0.132-0.159-0.132-0.302l1.079-12.431
	c0.024-0.275,0.056-0.466,0.094-0.57s0.132-0.173,0.282-0.207c0.761-0.15,1.547-0.303,2.358-0.458
	c0.811-0.154,1.598-0.353,2.358-0.596c0.066,0,0.106,0.011,0.119,0.031c0.013,0.021,0.019,0.052,0.019,0.094
	c0,0.018-0.002,0.043-0.006,0.075c-0.005,0.034-0.018,0.109-0.038,0.226c-0.021,0.118-0.05,0.295-0.088,0.534
	c-0.037,0.238-0.09,0.57-0.156,0.997c0.385-0.301,0.809-0.577,1.272-0.828c0.465-0.251,0.931-0.466,1.399-0.646
	c0.468-0.18,0.919-0.322,1.354-0.427c0.435-0.104,0.815-0.157,1.142-0.157L175.222,10.193z"/>
              <path fill="#E92328" d="M24.086,11.019c-0.121,0.378-0.27,0.739-0.423,1.095c-2.274,5.252-5.618,9.123-9.725,12.004
	c-0.214,0.149-0.488,0.226-0.749,0.235c-1.782,0.067-3.568,0.112-5.354,0.157c-1.552,0.039-3.104,0.068-4.657,0.104l-0.087-0.361
	c0.607-0.157,1.197-0.414,1.823-0.455c2.561-0.166,5.134-0.26,7.698-0.4c0.257-0.014,0.551-0.076,0.729-0.26
	c0.488-0.507,0.957-1.051,1.374-1.649c1.958-2.814,2.902-6.312,3.131-10.246c0.054-0.93,0.313-1.26,1.051-1.26
	c1.496,0,2.974-0.136,4.462-0.193C24.126,9.759,24.36,10.163,24.086,11.019 M1.7,22.646c-0.248,0.01-0.536-0.246-0.806-0.378
	c0.107-0.289,0.164-0.642,0.33-0.857c1.023-1.335,2.203-2.503,3.083-3.982c1.768-2.97,2.411-6.563,1.963-10.64
	C6.159,5.781,6.349,4.978,7.011,4.562c0.157-0.087,0.267-0.177,0.393-0.216c2.568-0.803,5.132-1.621,7.712-2.387
	c0.991-0.294,1.695,0.471,1.817,1.848c0.243,2.752,0.232,5.446-0.111,8.065c-0.528,4.032-1.792,7.472-4.234,9.94
	c-0.185,0.187-0.438,0.342-0.685,0.355C8.506,22.35,5.105,22.513,1.7,22.646 M25.38,5.966c-0.374-1.421-1.004-2.58-1.888-3.478
	c-0.885-0.897-2.044-1.557-3.478-1.98c-1.434-0.425-3.209-0.636-5.328-0.636c-0.848,0-2.013,0.056-3.496,0.168
	C9.708,0.152,8.119,0.208,6.424,0.208c-0.125,0-0.256,0.044-0.392,0.131C5.894,0.427,5.813,0.57,5.788,0.77
	c-0.025,0.398-0.043,0.81-0.056,1.233C5.719,2.427,5.695,3.056,5.657,3.891C5.644,4.188,5.626,4.528,5.603,4.906
	C5.562,5.59,5.505,6.402,5.434,7.349C5.432,7.367,5.43,7.387,5.429,7.405c-0.112,1.465-0.266,3.414-0.463,5.852
	c-0.012,0.149-0.024,0.295-0.037,0.442c-0.327,1.168-0.79,2.261-1.391,3.271c-0.538,0.904-1.2,1.688-1.899,2.519
	c-0.373,0.441-0.758,0.898-1.125,1.377c-0.214,0.279-0.309,0.603-0.386,0.861c-0.023,0.078-0.045,0.156-0.072,0.229
	c-0.159,0.431,0.033,0.912,0.444,1.114c0.06,0.03,0.12,0.069,0.182,0.107c0.248,0.154,0.583,0.362,1.004,0.362
	c0.017,0,0.033,0,0.05-0.001c0.292-0.012,0.596-0.024,0.892-0.036c-0.321,0.199-0.5,0.577-0.404,0.963l0.086,0.357
	c0.098,0.401,0.457,0.684,0.869,0.684h0.02l1.444-0.03c0.068-0.002,0.136-0.004,0.204-0.005c0.003,0.004,0.004,0.008,0.008,0.012
	c0.398,0.498,0.959,0.872,1.682,1.12c0.723,0.25,1.589,0.399,2.599,0.449s2.125,0.075,3.346,0.075c2.643,0,4.83-0.454,6.563-1.364
	s3.109-2.113,4.131-3.608c1.022-1.496,1.739-3.209,2.15-5.141s0.617-3.907,0.617-5.927C25.941,9.094,25.754,7.387,25.38,5.966"/>
              <path fill="#E92328" d="M15.885,11.405c0.333-2.645,0.358-5.359,0.004-8.354c-0.634,0.186-1.162,0.332-1.685,0.493
	c-2.062,0.638-4.118,1.294-6.186,1.91c-0.63,0.188-0.881,0.62-0.789,1.5c0.606,5.786-0.927,10.266-3.87,13.873
	c-0.105,0.13-0.194,0.279-0.385,0.556c0.824-0.044,1.525-0.085,2.228-0.119c1.982-0.093,3.97-0.152,5.943-0.297
	c0.416-0.03,0.863-0.248,1.157-0.574C14.38,18.096,15.435,14.974,15.885,11.405"/>
              <path d="M173.303,19.042h0.354c0.122,0,0.2-0.016,0.231-0.045c0.031-0.03,0.047-0.099,0.047-0.205c0-0.107-0.021-0.174-0.063-0.2
	c-0.043-0.027-0.114-0.04-0.215-0.04h-0.354V19.042z M173.731,18.146c0.422,0,0.632,0.211,0.632,0.632
	c0,0.201-0.033,0.346-0.1,0.435c-0.065,0.088-0.154,0.147-0.268,0.179l0.41,0.769h-0.467l-0.392-0.75l-0.245-0.056v0.806h-0.429
	v-2.014H173.731z M172.331,19.136c0,0.255,0.026,0.479,0.078,0.674c0.052,0.195,0.138,0.354,0.257,0.479
	c0.119,0.124,0.264,0.186,0.434,0.186h1.066c0.138,0,0.257-0.043,0.355-0.129c0.099-0.087,0.179-0.23,0.24-0.432
	s0.092-0.458,0.092-0.769c0-0.412-0.033-0.702-0.099-0.872c-0.079-0.198-0.178-0.325-0.297-0.382
	c-0.079-0.038-0.167-0.057-0.265-0.057H173.1c-0.223,0-0.401,0.083-0.537,0.25C172.408,18.276,172.331,18.627,172.331,19.136
	 M171.902,19.103c0-0.156,0.01-0.312,0.028-0.464c0.02-0.152,0.055-0.307,0.106-0.462s0.121-0.286,0.207-0.392
	c0.087-0.105,0.203-0.19,0.349-0.257c0.146-0.066,0.315-0.099,0.507-0.099h1.094c0.183,0,0.34,0.034,0.474,0.104
	c0.135,0.069,0.239,0.156,0.316,0.262s0.139,0.236,0.187,0.394c0.075,0.258,0.113,0.577,0.113,0.957
	c0,0.547-0.094,0.973-0.281,1.277c-0.187,0.305-0.456,0.457-0.809,0.457H173.1c-0.354,0-0.636-0.138-0.844-0.414
	C172.021,20.154,171.902,19.7,171.902,19.103"/>
            </g>
          </IconBase>
        );
      case 'ducktransfer-white':
        return (
          <IconBase viewBox="0 0 176 27" {...this.props}>
            <g>
              <path fill="#E92328" d="M41.628,18.849c-0.025,0.276-0.059,0.467-0.1,0.571c-0.042,0.104-0.134,0.173-0.276,0.207
	c-0.711,0.2-1.453,0.403-2.227,0.608c-0.774,0.205-1.524,0.475-2.252,0.809c-0.025,0.009-0.063,0.013-0.113,0.013
	c-0.067,0-0.106-0.017-0.119-0.05c-0.013-0.034-0.019-0.071-0.019-0.113c0-0.017,0.001-0.046,0.006-0.088s0.015-0.119,0.031-0.231
	c0.017-0.114,0.04-0.27,0.069-0.471c0.029-0.201,0.069-0.468,0.119-0.803c-0.937,0.61-1.767,1.056-2.49,1.336
	s-1.365,0.42-1.925,0.42c-1.396,0-2.415-0.337-3.055-1.01c-0.639-0.673-0.959-1.679-0.959-3.018c0-0.242,0.004-0.49,0.012-0.745
	c0.008-0.256,0.033-0.517,0.075-0.785c0.092-0.986,0.171-1.833,0.238-2.54c0.067-0.706,0.121-1.307,0.163-1.8
	c0.042-0.493,0.077-0.896,0.106-1.21c0.03-0.313,0.054-0.575,0.075-0.784s0.038-0.383,0.05-0.521
	c0.013-0.139,0.027-0.274,0.044-0.408c0.034-0.267,0.065-0.455,0.094-0.564c0.029-0.108,0.123-0.18,0.282-0.213
	c0.393-0.075,0.767-0.145,1.123-0.207s0.707-0.13,1.054-0.201c0.347-0.07,0.7-0.147,1.06-0.231s0.74-0.184,1.142-0.301
	c0.017,0,0.033-0.002,0.05-0.007c0.017-0.004,0.033-0.006,0.05-0.006c0.101,0,0.151,0.033,0.151,0.1v0.025
	c-0.034,0.284-0.063,0.552-0.088,0.803s-0.05,0.509-0.075,0.771c-0.025,0.264-0.052,0.547-0.082,0.847
	c-0.03,0.302-0.065,0.651-0.106,1.048c-0.042,0.396-0.092,0.855-0.151,1.373c-0.059,0.519-0.13,1.121-0.213,1.807
	c-0.051,0.502-0.101,1.002-0.151,1.499s-0.075,0.959-0.075,1.386c0,0.234,0.017,0.45,0.05,0.646
	c0.034,0.196,0.094,0.364,0.182,0.501c0.088,0.139,0.203,0.245,0.345,0.32c0.142,0.075,0.326,0.113,0.551,0.113
	c0.176,0,0.415-0.04,0.716-0.119c0.301-0.079,0.602-0.218,0.903-0.414c0.301-0.197,0.566-0.455,0.796-0.777s0.362-0.729,0.396-1.224
	c0.058-0.827,0.11-1.52,0.157-2.075c0.045-0.556,0.085-1.024,0.119-1.406c0.033-0.38,0.063-0.699,0.087-0.959
	c0.025-0.259,0.048-0.508,0.069-0.746s0.04-0.493,0.057-0.766c0.017-0.271,0.033-0.612,0.05-1.022
	c0.025-0.276,0.057-0.466,0.094-0.57s0.132-0.174,0.282-0.207c0.761-0.151,1.539-0.303,2.333-0.457
	c0.794-0.155,1.572-0.354,2.333-0.597c0.067,0,0.107,0.01,0.119,0.031c0.013,0.021,0.019,0.052,0.019,0.094L41.628,18.849z"/>
              <path fill="#E92328" d="M44.175,14.283c0-1.096,0.203-2.122,0.608-3.08c0.405-0.957,0.966-1.792,1.681-2.502
	c0.715-0.711,1.56-1.271,2.534-1.682c0.974-0.409,2.035-0.614,3.18-0.614c0.778,0,1.442,0.063,1.995,0.188
	c0.552,0.125,1.003,0.266,1.354,0.42c0.351,0.155,0.608,0.297,0.771,0.427c0.163,0.129,0.245,0.198,0.245,0.207
	c0,0.05-0.019,0.189-0.057,0.42c-0.037,0.229-0.079,0.497-0.125,0.803c-0.046,0.306-0.088,0.619-0.125,0.94
	c-0.038,0.322-0.056,0.601-0.056,0.835c0,0.042-0.013,0.087-0.038,0.137c-0.025,0.052-0.092,0.076-0.2,0.076
	c-0.101,0-0.247-0.036-0.439-0.106c-0.192-0.071-0.429-0.148-0.709-0.232c-0.28-0.083-0.602-0.161-0.966-0.231
	c-0.363-0.071-0.768-0.107-1.21-0.107c-0.552,0-1.05,0.099-1.493,0.295c-0.443,0.196-0.824,0.469-1.141,0.815
	c-0.318,0.347-0.563,0.759-0.734,1.236c-0.171,0.477-0.257,0.991-0.257,1.542c0,0.577,0.089,1.068,0.27,1.475
	c0.18,0.405,0.42,0.736,0.722,0.99c0.301,0.256,0.648,0.441,1.041,0.559c0.393,0.117,0.807,0.176,1.242,0.176
	c0.502,0,1.008-0.069,1.518-0.207s0.991-0.332,1.443-0.583c0.016-0.009,0.05-0.013,0.1-0.013c0.042,0,0.073,0.021,0.094,0.063
	c0.021,0.041,0.031,0.121,0.031,0.238v0.972c0,0.381-0.002,0.745-0.007,1.092c-0.003,0.348-0.01,0.643-0.018,0.885
	c-0.009,0.242-0.017,0.363-0.025,0.363c-0.301,0.2-0.627,0.364-0.978,0.489c-0.352,0.125-0.713,0.227-1.085,0.302
	c-0.373,0.075-0.746,0.124-1.123,0.15c-0.376,0.024-0.74,0.037-1.091,0.037c-0.979,0-1.89-0.163-2.735-0.489
	s-1.578-0.786-2.201-1.38s-1.114-1.307-1.474-2.139C44.354,16.217,44.175,15.295,44.175,14.283"/>
              <path fill="#E92328" d="M64.396,1.362l-0.451,4.354c-0.059,0.585-0.12,1.179-0.183,1.781c-0.063,0.602-0.124,1.179-0.182,1.731
	c-0.059,0.551-0.113,1.062-0.163,1.529c-0.05,0.469-0.092,0.854-0.125,1.154l0.866-0.953l1.405-1.644
	c0.176-0.2,0.347-0.401,0.514-0.602c0.167-0.201,0.318-0.379,0.451-0.534c0.134-0.154,0.243-0.283,0.326-0.388
	c0.084-0.105,0.134-0.166,0.15-0.183l5.043-0.351c0.024-0.009,0.052-0.013,0.082-0.013h0.082c0.142,0,0.213,0.037,0.213,0.112
	c0,0.06-0.088,0.188-0.264,0.389l-3.926,4.392c-0.176,0.191-0.383,0.415-0.621,0.67c-0.238,0.256-0.504,0.538-0.796,0.848
	l1.832,2.182l3.161,3.4c0.024,0.041,0.063,0.073,0.112,0.094c0.051,0.021,0.099,0.04,0.145,0.057s0.088,0.035,0.125,0.057
	c0.038,0.021,0.056,0.051,0.056,0.094c0,0.032-0.006,0.059-0.019,0.075s-0.052,0.05-0.119,0.101
	c-0.075,0.059-0.207,0.142-0.395,0.251c-0.188,0.108-0.408,0.229-0.659,0.363s-0.519,0.27-0.803,0.408
	c-0.284,0.138-0.561,0.264-0.828,0.376c-0.268,0.113-0.514,0.205-0.741,0.276c-0.226,0.07-0.401,0.106-0.526,0.106
	c-0.101,0-0.199-0.025-0.295-0.075c-0.097-0.051-0.203-0.143-0.32-0.276c-0.159-0.184-0.357-0.425-0.596-0.722
	c-0.238-0.297-0.496-0.622-0.771-0.978c-0.276-0.356-0.558-0.724-0.847-1.104c-0.289-0.38-0.565-0.744-0.828-1.092
	c-0.263-0.347-0.501-0.66-0.715-0.94s-0.378-0.504-0.496-0.671c-0.042,0.042-0.084,0.073-0.125,0.094
	c-0.042,0.021-0.082,0.048-0.119,0.082c-0.038,0.033-0.069,0.073-0.094,0.119s-0.038,0.11-0.038,0.194
	c0,0.033-0.009,0.128-0.025,0.282c-0.017,0.155-0.036,0.339-0.057,0.552c-0.021,0.213-0.044,0.441-0.069,0.684
	c-0.025,0.243-0.048,0.471-0.069,0.684c-0.021,0.214-0.04,0.397-0.057,0.552c-0.017,0.155-0.025,0.249-0.025,0.283
	c0,0.058,0.001,0.106,0.006,0.144c0.003,0.038,0.006,0.073,0.006,0.107c0,0.092-0.032,0.16-0.094,0.206
	c-0.063,0.047-0.215,0.103-0.458,0.17l-3.286,1.066c-0.093,0.033-0.214,0.075-0.364,0.125c-0.151,0.051-0.297,0.075-0.439,0.075
	c-0.117,0-0.22-0.029-0.307-0.088c-0.088-0.059-0.132-0.167-0.132-0.326l1.581-18.075c0-0.042-0.004-0.082-0.013-0.12
	c-0.009-0.037-0.012-0.077-0.012-0.119c0-0.075,0.031-0.145,0.094-0.207s0.207-0.123,0.433-0.182l3.524-0.84
	c0.092-0.018,0.213-0.055,0.364-0.113c0.151-0.058,0.297-0.088,0.439-0.088c0.108,0,0.211,0.025,0.307,0.075
	c0.096,0.051,0.144,0.146,0.144,0.289c0,0.017-0.002,0.033-0.006,0.05C64.398,1.329,64.396,1.347,64.396,1.362"/>
              <path fill="#FFFFFF" d="M75.182,20.806c0.042-0.61,0.083-1.2,0.125-1.769c0.041-0.568,0.079-1.121,0.113-1.656l0.452-5.92
	c0.05-0.452,0.091-0.864,0.125-1.236s0.061-0.735,0.082-1.091c0.021-0.355,0.042-0.716,0.063-1.079
	c0.021-0.363,0.044-0.767,0.069-1.211c-0.276-0.008-0.567-0.012-0.872-0.012h-0.96h-2.42c-0.092,0-0.149-0.036-0.17-0.106
	c-0.021-0.071-0.031-0.137-0.031-0.195V6.442l1.38-3.625l-0.013-0.05c0-0.042,0.027-0.077,0.081-0.106
	c0.055-0.029,0.178-0.053,0.371-0.069c1.154-0.067,2.273-0.132,3.355-0.194c1.083-0.063,2.154-0.119,3.211-0.169
	s2.115-0.101,3.174-0.15c1.058-0.051,2.146-0.097,3.268-0.139c0.1,0,0.207,0.013,0.32,0.038c0.112,0.024,0.168,0.117,0.168,0.276
	c0,0.017-0.004,0.032-0.012,0.05c-0.008,0.017-0.013,0.033-0.013,0.05c-0.133,0.343-0.257,0.669-0.37,0.979
	c-0.112,0.31-0.224,0.614-0.333,0.915c-0.108,0.302-0.219,0.607-0.332,0.917c-0.113,0.309-0.237,0.635-0.37,0.978
	c-0.008,0.009-0.025,0.049-0.05,0.119c-0.025,0.071-0.063,0.15-0.112,0.238c-0.051,0.088-0.118,0.17-0.201,0.245
	s-0.188,0.117-0.313,0.125c-0.042-0.008-0.083-0.013-0.126-0.013h-0.138h-3.349c-0.042,0.427-0.082,0.879-0.119,1.355
	s-0.082,0.94-0.131,1.393l-0.917,9.797c-0.008,0.075-0.023,0.15-0.043,0.226s-0.077,0.13-0.169,0.163l-3.55,1.279
	c-0.092,0.033-0.213,0.075-0.364,0.125c-0.15,0.051-0.297,0.076-0.439,0.076c-0.117,0-0.219-0.03-0.307-0.088
	c-0.088-0.06-0.131-0.168-0.131-0.326V20.806z"/>
              <path fill="#FFFFFF" d="M95.643,10.193c-0.175-0.017-0.371-0.035-0.589-0.057c-0.218-0.021-0.448-0.031-0.69-0.031
	c-0.367,0-0.741,0.031-1.122,0.095c-0.381,0.063-0.734,0.187-1.061,0.37s-0.606,0.437-0.84,0.759
	c-0.234,0.322-0.385,0.738-0.452,1.248c-0.059,0.619-0.106,1.127-0.144,1.523c-0.038,0.397-0.065,0.725-0.082,0.979
	c-0.017,0.256-0.031,0.46-0.044,0.615c-0.013,0.154-0.023,0.299-0.031,0.433c0,0.034-0.007,0.126-0.019,0.276
	c-0.013,0.15-0.032,0.326-0.057,0.526c-0.025,0.2-0.048,0.418-0.069,0.652c-0.021,0.234-0.044,0.451-0.068,0.652
	c-0.025,0.2-0.044,0.376-0.057,0.526s-0.02,0.242-0.02,0.276c0,0.067,0.003,0.123,0.007,0.17c0.004,0.045,0.006,0.09,0.006,0.132
	c0,0.066-0.031,0.123-0.094,0.169s-0.203,0.099-0.42,0.156l-3.325,1.192c-0.093,0.033-0.213,0.075-0.364,0.125
	c-0.15,0.051-0.297,0.075-0.438,0.075c-0.118,0-0.22-0.029-0.307-0.088c-0.089-0.059-0.133-0.159-0.133-0.302L86.31,8.236
	c0.025-0.275,0.057-0.466,0.094-0.57s0.131-0.173,0.282-0.207c0.761-0.15,1.547-0.303,2.359-0.458
	c0.811-0.154,1.598-0.353,2.358-0.596c0.066,0,0.106,0.011,0.119,0.031c0.013,0.021,0.019,0.052,0.019,0.094
	c0,0.018-0.002,0.043-0.006,0.075c-0.005,0.034-0.018,0.109-0.038,0.226c-0.021,0.118-0.05,0.295-0.088,0.534
	c-0.037,0.238-0.09,0.57-0.156,0.997c0.384-0.301,0.809-0.577,1.272-0.828c0.465-0.251,0.931-0.466,1.399-0.646
	c0.468-0.18,0.919-0.322,1.354-0.427c0.435-0.104,0.815-0.157,1.142-0.157L95.643,10.193z"/>
              <path fill="#FFFFFF" d="M105.565,13.154c0.083-0.477,0.14-0.927,0.169-1.35c0.029-0.422,0.053-0.809,0.069-1.159
	c-0.134-0.102-0.278-0.205-0.433-0.314c-0.155-0.108-0.322-0.211-0.502-0.307c-0.18-0.097-0.368-0.174-0.564-0.232
	s-0.403-0.088-0.621-0.088c-0.451,0-0.83,0.146-1.135,0.439c-0.306,0.293-0.553,0.652-0.74,1.078
	c-0.188,0.427-0.322,0.878-0.401,1.355c-0.08,0.477-0.119,0.898-0.119,1.267c0,1.321,0.173,2.29,0.521,2.904
	c0.347,0.614,0.83,0.922,1.448,0.922c0.251,0,0.531-0.071,0.841-0.214c0.31-0.142,0.623-0.339,0.94-0.589L105.565,13.154z
	 M109.316,17.883c-0.009,0.033-0.013,0.065-0.013,0.095v0.081c0,0.209,0.033,0.383,0.101,0.521c0.066,0.138,0.14,0.251,0.219,0.339
	c0.08,0.088,0.152,0.158,0.22,0.214c0.066,0.054,0.101,0.102,0.101,0.144c0,0.117-0.126,0.226-0.376,0.326l-3.375,1.442
	c-0.009,0.009-0.037,0.013-0.088,0.013c-0.067,0-0.134-0.059-0.2-0.176c-0.067-0.117-0.132-0.265-0.194-0.445
	c-0.063-0.18-0.121-0.371-0.176-0.577c-0.055-0.205-0.103-0.395-0.145-0.57c-0.526,0.61-1.096,1.059-1.706,1.342
	c-0.61,0.284-1.217,0.427-1.819,0.427c-0.702,0-1.388-0.154-2.057-0.465c-0.669-0.309-1.263-0.75-1.781-1.322
	c-0.519-0.573-0.935-1.266-1.248-2.076c-0.313-0.812-0.471-1.723-0.471-2.735c0-1.195,0.174-2.287,0.521-3.273
	c0.348-0.987,0.817-1.836,1.411-2.547c0.595-0.71,1.286-1.261,2.076-1.649c0.791-0.389,1.629-0.583,2.516-0.583
	c0.627,0,1.221,0.128,1.781,0.382c0.56,0.256,1.091,0.584,1.593,0.985c0.051-0.251,0.079-0.452,0.088-0.603
	c0.008-0.15,0.027-0.268,0.057-0.351s0.086-0.141,0.169-0.17c0.084-0.028,0.222-0.052,0.414-0.068
	c0.603-0.084,1.202-0.161,1.8-0.232s1.224-0.123,1.876-0.156L109.316,17.883z"/>
              <path fill="#FFFFFF" d="M122.575,20.856c-0.092,0.024-0.213,0.064-0.363,0.119c-0.151,0.054-0.298,0.081-0.439,0.081
	c-0.117,0-0.22-0.029-0.308-0.088s-0.132-0.159-0.132-0.301v-0.075c0.05-0.452,0.092-0.834,0.126-1.147
	c0.033-0.313,0.064-0.599,0.094-0.854s0.054-0.51,0.075-0.765c0.021-0.256,0.048-0.553,0.081-0.892
	c0.033-0.338,0.073-0.739,0.119-1.204c0.047-0.464,0.104-1.043,0.17-1.737c0.042-0.427,0.08-0.86,0.112-1.305
	c0.034-0.443,0.051-0.853,0.051-1.229c0-0.251-0.02-0.483-0.057-0.696c-0.038-0.213-0.104-0.397-0.201-0.552
	c-0.096-0.155-0.228-0.274-0.395-0.358c-0.167-0.083-0.381-0.125-0.64-0.125c-0.192,0-0.458,0.042-0.797,0.125
	c-0.339,0.084-0.676,0.222-1.01,0.414c-0.335,0.193-0.631,0.446-0.891,0.759c-0.259,0.314-0.406,0.701-0.438,1.161
	c-0.05,0.618-0.097,1.147-0.139,1.586c-0.042,0.439-0.079,0.812-0.113,1.117c-0.032,0.305-0.061,0.554-0.081,0.746
	s-0.035,0.355-0.044,0.489c-0.009,0.034-0.021,0.126-0.038,0.276c-0.016,0.15-0.035,0.33-0.056,0.539
	c-0.021,0.209-0.042,0.431-0.063,0.665c-0.021,0.234-0.041,0.455-0.063,0.664c-0.021,0.21-0.037,0.389-0.05,0.539
	c-0.013,0.151-0.019,0.243-0.019,0.277c0,0.066,0.002,0.123,0.006,0.169s0.006,0.09,0.006,0.132c0,0.066-0.031,0.123-0.094,0.169
	s-0.202,0.099-0.42,0.157l-3.324,1.142c-0.093,0.033-0.213,0.075-0.364,0.125c-0.15,0.05-0.297,0.075-0.438,0.075
	c-0.117,0-0.22-0.029-0.308-0.088S112,20.81,112,20.668l1.142-12.82c0.025-0.276,0.057-0.466,0.095-0.57
	c0.037-0.104,0.131-0.174,0.282-0.207c0.761-0.151,1.543-0.304,2.346-0.458s1.585-0.354,2.346-0.596c0.066,0,0.106,0.01,0.119,0.031
	s0.019,0.052,0.019,0.094c0,0.018-0.002,0.042-0.006,0.075c-0.005,0.033-0.018,0.109-0.038,0.226
	c-0.021,0.117-0.05,0.295-0.088,0.533c-0.037,0.239-0.09,0.571-0.156,0.998c0.576-0.335,1.118-0.604,1.624-0.81
	s0.983-0.363,1.43-0.477c0.447-0.113,0.868-0.188,1.262-0.226c0.393-0.038,0.76-0.057,1.104-0.057c1.179,0,2.036,0.357,2.571,1.072
	s0.803,1.788,0.803,3.218c0,0.2-0.004,0.407-0.013,0.621c-0.008,0.213-0.025,0.433-0.05,0.658l-0.577,5.395
	c-0.042,0.352-0.067,0.675-0.075,0.972c-0.009,0.297-0.013,0.642-0.013,1.035c0,0.066,0.002,0.123,0.007,0.169
	c0.004,0.046,0.006,0.086,0.006,0.119c0,0.075-0.031,0.134-0.095,0.176c-0.063,0.042-0.202,0.097-0.42,0.163L122.575,20.856z"/>
              <path fill="#FFFFFF" d="M128.81,16.942c0-0.008-0.002-0.015-0.007-0.02c-0.004-0.004-0.006-0.01-0.006-0.019
	c0-0.107,0.059-0.163,0.176-0.163c0.075,0,0.143,0.029,0.2,0.088c0.334,0.318,0.718,0.577,1.148,0.778
	c0.43,0.2,0.896,0.301,1.398,0.301c0.134,0,0.288-0.015,0.464-0.044s0.343-0.077,0.502-0.145c0.159-0.066,0.29-0.156,0.396-0.27
	c0.104-0.112,0.156-0.253,0.156-0.42c0-0.185-0.046-0.354-0.138-0.509c-0.093-0.154-0.214-0.281-0.364-0.382
	c-0.084-0.059-0.23-0.161-0.439-0.308s-0.432-0.303-0.67-0.471c-0.239-0.167-0.465-0.326-0.678-0.477s-0.362-0.259-0.445-0.326
	c-0.376-0.284-0.675-0.572-0.896-0.865s-0.392-0.596-0.508-0.909c-0.118-0.314-0.193-0.643-0.227-0.984
	c-0.033-0.344-0.05-0.707-0.05-1.092c0-0.586,0.154-1.138,0.464-1.656s0.717-0.975,1.223-1.367c0.506-0.394,1.081-0.704,1.725-0.935
	c0.645-0.229,1.301-0.345,1.97-0.345c0.552,0,1.096,0.083,1.631,0.251c0.535,0.167,1.032,0.451,1.493,0.853
	c0.041,0.527,0.07,1.046,0.087,1.556c0.018,0.51,0.043,1.045,0.075,1.605c0,0.109-0.024,0.183-0.075,0.22
	c-0.05,0.038-0.108,0.057-0.175,0.057c-0.06,0-0.109-0.021-0.15-0.063c-0.193-0.184-0.39-0.365-0.59-0.545
	c-0.201-0.18-0.412-0.339-0.634-0.477s-0.458-0.251-0.709-0.339s-0.519-0.132-0.803-0.132c-0.176,0-0.351,0.015-0.526,0.044
	s-0.335,0.084-0.477,0.163c-0.143,0.079-0.257,0.187-0.346,0.32c-0.087,0.134-0.132,0.306-0.132,0.514
	c0,0.243,0.03,0.451,0.088,0.627c0.059,0.176,0.196,0.348,0.414,0.515l2.333,1.581c0.427,0.293,0.771,0.579,1.035,0.858
	c0.264,0.281,0.47,0.559,0.621,0.835c0.15,0.275,0.253,0.55,0.308,0.821c0.055,0.272,0.081,0.542,0.081,0.81
	c0,0.753-0.173,1.415-0.521,1.987c-0.347,0.573-0.796,1.051-1.349,1.431c-0.552,0.381-1.177,0.669-1.875,0.865
	s-1.398,0.295-2.102,0.295c-0.534,0-1.052-0.057-1.549-0.169c-0.497-0.113-0.959-0.287-1.386-0.521L128.81,16.942z"/>
              <path fill="#FFFFFF" d="M140.839,20.593c0.008-0.093,0.027-0.291,0.057-0.597c0.029-0.305,0.064-0.691,0.106-1.159
	c0.042-0.469,0.09-1.006,0.145-1.612c0.054-0.606,0.114-1.258,0.182-1.957c0.066-0.698,0.136-1.428,0.207-2.188
	c0.071-0.762,0.145-1.531,0.22-2.309h-1.669c-0.451,0-0.777-0.019-0.979-0.057c-0.2-0.037-0.301-0.114-0.301-0.232
	c0-0.032,0.042-0.123,0.126-0.269c0.083-0.147,0.189-0.322,0.319-0.527s0.27-0.425,0.42-0.658c0.151-0.234,0.295-0.454,0.434-0.659
	c0.138-0.204,0.257-0.382,0.357-0.533c0.1-0.15,0.163-0.242,0.188-0.276h1.53l0.251-1.993c0.117-1.055,0.328-1.94,0.634-2.66
	c0.306-0.719,0.686-1.301,1.142-1.743c0.455-0.443,0.977-0.761,1.562-0.953c0.586-0.192,1.213-0.289,1.882-0.289
	s1.365,0.082,2.089,0.245c0.723,0.163,1.452,0.387,2.189,0.671c0.157,0.067,0.277,0.123,0.356,0.17
	c0.079,0.045,0.119,0.105,0.119,0.181c0,0.067-0.029,0.155-0.088,0.264c-0.268,0.519-0.466,0.937-0.596,1.255
	c-0.13,0.317-0.232,0.568-0.308,0.752c-0.075,0.185-0.138,0.308-0.188,0.371c-0.051,0.063-0.126,0.094-0.227,0.094
	c-0.05,0-0.103-0.013-0.156-0.038c-0.055-0.025-0.119-0.055-0.194-0.088c-0.293-0.108-0.588-0.207-0.885-0.295
	s-0.579-0.132-0.847-0.132c-0.376,0-0.7,0.118-0.973,0.352c-0.271,0.234-0.487,0.678-0.646,1.329
	c-0.092,0.377-0.167,0.783-0.226,1.218s-0.109,0.865-0.15,1.291h3.273c0.067,0,0.132,0.023,0.195,0.069
	c0.063,0.046,0.093,0.124,0.093,0.232c0,0.042-0.049,0.192-0.15,0.451c-0.1,0.26-0.242,0.599-0.426,1.017l-0.452,1.016
	c-0.058,0.125-0.119,0.218-0.182,0.276s-0.19,0.096-0.383,0.113h-2.17c-0.092,0.869-0.18,1.73-0.264,2.583
	c-0.083,0.854-0.163,1.654-0.238,2.403c-0.075,0.748-0.143,1.413-0.2,1.994c-0.059,0.581-0.105,1.03-0.138,1.349
	c-0.009,0.024-0.013,0.07-0.013,0.138c0,0.033,0.002,0.063,0.006,0.088c0.004,0.024,0.007,0.046,0.007,0.063
	c0,0.075-0.027,0.136-0.082,0.183c-0.055,0.045-0.194,0.098-0.42,0.156l-3.299,1.166c-0.093,0.034-0.214,0.075-0.364,0.126
	c-0.151,0.05-0.297,0.075-0.438,0.075c-0.143,0-0.251-0.033-0.326-0.101C140.876,20.89,140.839,20.768,140.839,20.593"/>
              <path fill="#FFFFFF" d="M157.962,9.742c-0.344,0-0.659,0.073-0.947,0.219c-0.289,0.147-0.552,0.355-0.79,0.628
	c-0.239,0.271-0.445,0.598-0.621,0.979s-0.318,0.801-0.427,1.261c0.861-0.151,1.576-0.31,2.146-0.477
	c0.568-0.168,1.023-0.337,1.367-0.509c0.342-0.171,0.584-0.343,0.728-0.514c0.142-0.172,0.213-0.337,0.213-0.496
	c0-0.15-0.057-0.292-0.169-0.427c-0.113-0.133-0.254-0.251-0.421-0.351c-0.167-0.101-0.35-0.178-0.546-0.232
	C158.298,9.77,158.12,9.742,157.962,9.742 M158.112,6.405c0.794,0,1.516,0.148,2.163,0.445c0.648,0.297,1.204,0.682,1.669,1.154
	c0.464,0.473,0.821,1.001,1.072,1.587c0.251,0.585,0.376,1.171,0.376,1.756c0,0.519-0.159,1.039-0.477,1.562
	c-0.317,0.523-0.764,0.993-1.336,1.411c-0.572,0.419-1.261,0.758-2.063,1.017s-1.686,0.389-2.646,0.389
	c-0.502,0-1.029-0.051-1.581-0.15c0.034,0.359,0.139,0.669,0.314,0.928c0.176,0.26,0.396,0.473,0.665,0.64
	c0.267,0.168,0.57,0.291,0.909,0.371c0.339,0.079,0.688,0.119,1.047,0.119c0.401,0,0.788-0.036,1.16-0.107s0.729-0.165,1.073-0.282
	c0.343-0.117,0.673-0.246,0.99-0.389c0.318-0.142,0.619-0.284,0.903-0.427c0.075-0.016,0.141-0.031,0.194-0.044
	c0.055-0.013,0.106-0.019,0.157-0.019c-0.009,0.268-0.013,0.522-0.013,0.766c0,0.242-0.002,0.535-0.006,0.878
	c-0.005,0.343,0.022,1.225,0.031,1.267c0.008,0.042,0.013,0.075,0.013,0.101c0,0.101-0.093,0.209-0.276,0.326
	s-0.425,0.238-0.722,0.363c-0.297,0.126-0.636,0.247-1.016,0.364c-0.381,0.117-0.768,0.224-1.16,0.319
	c-0.394,0.097-0.773,0.172-1.142,0.227c-0.368,0.054-0.689,0.081-0.966,0.081c-0.903,0-1.779-0.156-2.628-0.471
	c-0.85-0.313-1.602-0.763-2.258-1.348c-0.657-0.586-1.182-1.296-1.574-2.133c-0.394-0.836-0.59-1.777-0.59-2.822
	c0-1.096,0.188-2.122,0.564-3.08c0.376-0.957,0.905-1.792,1.587-2.502c0.682-0.712,1.494-1.271,2.439-1.682
	C155.934,6.61,156.975,6.405,158.112,6.405"/>
              <path fill="#FFFFFF" d="M175.222,10.193c-0.176-0.017-0.372-0.035-0.589-0.057c-0.218-0.021-0.448-0.031-0.69-0.031
	c-0.368,0-0.742,0.031-1.123,0.095c-0.38,0.063-0.733,0.187-1.06,0.37s-0.606,0.437-0.84,0.759
	c-0.234,0.322-0.386,0.738-0.452,1.248c-0.059,0.619-0.106,1.127-0.145,1.523c-0.037,0.397-0.064,0.725-0.081,0.979
	c-0.017,0.256-0.031,0.46-0.044,0.615c-0.013,0.154-0.023,0.299-0.031,0.433c0,0.034-0.007,0.126-0.019,0.276
	c-0.013,0.15-0.032,0.326-0.057,0.526c-0.025,0.2-0.049,0.418-0.069,0.652s-0.044,0.451-0.068,0.652
	c-0.025,0.2-0.044,0.376-0.057,0.526s-0.02,0.242-0.02,0.276c0,0.067,0.002,0.123,0.007,0.17c0.004,0.045,0.006,0.09,0.006,0.132
	c0,0.066-0.031,0.123-0.094,0.169s-0.203,0.099-0.42,0.156l-3.324,1.192c-0.093,0.033-0.214,0.075-0.364,0.125
	c-0.15,0.051-0.297,0.075-0.438,0.075c-0.118,0-0.22-0.029-0.308-0.088s-0.132-0.159-0.132-0.302l1.079-12.431
	c0.024-0.275,0.056-0.466,0.094-0.57s0.132-0.173,0.282-0.207c0.761-0.15,1.547-0.303,2.358-0.458
	c0.811-0.154,1.598-0.353,2.358-0.596c0.066,0,0.106,0.011,0.119,0.031c0.013,0.021,0.019,0.052,0.019,0.094
	c0,0.018-0.002,0.043-0.006,0.075c-0.005,0.034-0.018,0.109-0.038,0.226c-0.021,0.118-0.05,0.295-0.088,0.534
	c-0.037,0.238-0.09,0.57-0.156,0.997c0.385-0.301,0.809-0.577,1.272-0.828c0.465-0.251,0.931-0.466,1.399-0.646
	c0.468-0.18,0.919-0.322,1.354-0.427c0.435-0.104,0.815-0.157,1.142-0.157L175.222,10.193z"/>
              <path fill="#E92328" d="M24.086,11.019c-0.121,0.378-0.27,0.739-0.423,1.095c-2.274,5.252-5.618,9.123-9.725,12.004
	c-0.214,0.149-0.488,0.226-0.749,0.235c-1.782,0.067-3.568,0.112-5.354,0.157c-1.552,0.039-3.104,0.068-4.657,0.104l-0.087-0.361
	c0.607-0.157,1.197-0.414,1.823-0.455c2.561-0.166,5.134-0.26,7.698-0.4c0.257-0.014,0.551-0.076,0.729-0.26
	c0.488-0.507,0.957-1.051,1.374-1.649c1.958-2.814,2.902-6.312,3.131-10.246c0.054-0.93,0.313-1.26,1.051-1.26
	c1.496,0,2.974-0.136,4.462-0.193C24.126,9.759,24.36,10.163,24.086,11.019 M1.7,22.646c-0.248,0.01-0.536-0.246-0.806-0.378
	c0.107-0.289,0.164-0.642,0.33-0.857c1.023-1.335,2.203-2.503,3.083-3.982c1.768-2.97,2.411-6.563,1.963-10.64
	C6.159,5.781,6.349,4.978,7.011,4.562c0.157-0.087,0.267-0.177,0.393-0.216c2.568-0.803,5.132-1.621,7.712-2.387
	c0.991-0.294,1.695,0.471,1.817,1.848c0.243,2.752,0.232,5.446-0.111,8.065c-0.528,4.032-1.792,7.472-4.234,9.94
	c-0.185,0.187-0.438,0.342-0.685,0.355C8.506,22.35,5.105,22.513,1.7,22.646 M25.38,5.966c-0.374-1.421-1.004-2.58-1.888-3.478
	c-0.885-0.897-2.044-1.557-3.478-1.98c-1.434-0.425-3.209-0.636-5.328-0.636c-0.848,0-2.013,0.056-3.496,0.168
	C9.708,0.152,8.119,0.208,6.424,0.208c-0.125,0-0.256,0.044-0.392,0.131C5.894,0.427,5.813,0.57,5.788,0.77
	c-0.025,0.398-0.043,0.81-0.056,1.233C5.719,2.427,5.695,3.056,5.657,3.891C5.644,4.188,5.626,4.528,5.603,4.906
	C5.562,5.59,5.505,6.402,5.434,7.349C5.432,7.367,5.43,7.387,5.429,7.405c-0.112,1.465-0.266,3.414-0.463,5.852
	c-0.012,0.149-0.024,0.295-0.037,0.442c-0.327,1.168-0.79,2.261-1.391,3.271c-0.538,0.904-1.2,1.688-1.899,2.519
	c-0.373,0.441-0.758,0.898-1.125,1.377c-0.214,0.279-0.309,0.603-0.386,0.861c-0.023,0.078-0.045,0.156-0.072,0.229
	c-0.159,0.431,0.033,0.912,0.444,1.114c0.06,0.03,0.12,0.069,0.182,0.107c0.248,0.154,0.583,0.362,1.004,0.362
	c0.017,0,0.033,0,0.05-0.001c0.292-0.012,0.596-0.024,0.892-0.036c-0.321,0.199-0.5,0.577-0.404,0.963l0.086,0.357
	c0.098,0.401,0.457,0.684,0.869,0.684h0.02l1.444-0.03c0.068-0.002,0.136-0.004,0.204-0.005c0.003,0.004,0.004,0.008,0.008,0.012
	c0.398,0.498,0.959,0.872,1.682,1.12c0.723,0.25,1.589,0.399,2.599,0.449s2.125,0.075,3.346,0.075c2.643,0,4.83-0.454,6.563-1.364
	s3.109-2.113,4.131-3.608c1.022-1.496,1.739-3.209,2.15-5.141s0.617-3.907,0.617-5.927C25.941,9.094,25.754,7.387,25.38,5.966"/>
              <path fill="#E92328" d="M15.885,11.405c0.333-2.645,0.358-5.359,0.004-8.354c-0.634,0.186-1.162,0.332-1.685,0.493
	c-2.062,0.638-4.118,1.294-6.186,1.91c-0.63,0.188-0.881,0.62-0.789,1.5c0.606,5.786-0.927,10.266-3.87,13.873
	c-0.105,0.13-0.194,0.279-0.385,0.556c0.824-0.044,1.525-0.085,2.228-0.119c1.982-0.093,3.97-0.152,5.943-0.297
	c0.416-0.03,0.863-0.248,1.157-0.574C14.38,18.096,15.435,14.974,15.885,11.405"/>
              <path fill="#FFFFFF" d="M12.588,21.813c2.442-2.469,3.706-5.908,4.234-9.94c0.343-2.619,0.354-5.313,0.111-8.065
	c-0.122-1.377-0.826-2.142-1.817-1.848c-2.58,0.766-5.144,1.584-7.712,2.387C7.278,4.385,7.168,4.475,7.011,4.562
	C6.349,4.978,6.159,5.781,6.27,6.788c0.448,4.077-0.195,7.67-1.963,10.64c-0.88,1.479-2.06,2.647-3.083,3.982
	c-0.166,0.216-0.222,0.568-0.33,0.857c0.27,0.132,0.558,0.388,0.806,0.378c3.405-0.133,6.806-0.296,10.203-0.478
	C12.15,22.154,12.403,21.999,12.588,21.813z M5.204,21.265c-0.703,0.034-1.404,0.075-2.228,0.119
	c0.191-0.276,0.28-0.426,0.385-0.556c2.943-3.607,4.476-8.087,3.87-13.873c-0.093-0.88,0.158-1.312,0.789-1.5
	c2.068-0.616,4.124-1.272,6.186-1.91c0.523-0.161,1.05-0.308,1.685-0.493c0.354,2.994,0.329,5.709-0.004,8.354
	c-0.451,3.568-1.505,6.69-3.582,8.988c-0.294,0.326-0.741,0.544-1.157,0.574C9.173,21.112,7.186,21.172,5.204,21.265z"/>
              <path fill="#FFFFFF" d="M23.36,9.788c-1.489,0.058-2.967,0.193-4.462,0.193c-0.739,0-0.997,0.33-1.051,1.26
	c-0.229,3.935-1.173,7.432-3.131,10.246c-0.417,0.599-0.886,1.143-1.374,1.649c-0.178,0.184-0.472,0.246-0.729,0.26
	c-2.564,0.141-5.137,0.234-7.698,0.4c-0.625,0.041-1.216,0.298-1.823,0.455l0.087,0.361c1.552-0.035,3.105-0.064,4.657-0.104
	c1.785-0.045,3.571-0.09,5.354-0.157c0.26-0.01,0.535-0.086,0.749-0.235c4.107-2.881,7.451-6.752,9.725-12.004
	c0.153-0.355,0.302-0.717,0.423-1.095C24.36,10.163,24.126,9.759,23.36,9.788z"/>
              <path fill="#FFFFFF" d="M173.303,19.042h0.354c0.122,0,0.2-0.016,0.231-0.045c0.031-0.03,0.047-0.099,0.047-0.205
	c0-0.107-0.021-0.174-0.063-0.2c-0.043-0.027-0.114-0.04-0.215-0.04h-0.354V19.042z M173.731,18.146
	c0.422,0,0.632,0.211,0.632,0.632c0,0.201-0.033,0.346-0.1,0.435c-0.065,0.088-0.154,0.147-0.268,0.179l0.41,0.769h-0.467
	l-0.392-0.75l-0.245-0.056v0.806h-0.429v-2.014H173.731z M172.331,19.136c0,0.255,0.026,0.479,0.078,0.674
	c0.052,0.195,0.138,0.354,0.257,0.479c0.119,0.124,0.264,0.186,0.434,0.186h1.066c0.138,0,0.257-0.043,0.355-0.129
	c0.099-0.087,0.179-0.23,0.24-0.432s0.092-0.458,0.092-0.769c0-0.412-0.033-0.702-0.099-0.872c-0.079-0.198-0.178-0.325-0.297-0.382
	c-0.079-0.038-0.167-0.057-0.265-0.057H173.1c-0.223,0-0.401,0.083-0.537,0.25C172.408,18.276,172.331,18.627,172.331,19.136
	 M171.902,19.103c0-0.156,0.01-0.312,0.028-0.464c0.02-0.152,0.055-0.307,0.106-0.462s0.121-0.286,0.207-0.392
	c0.087-0.105,0.203-0.19,0.349-0.257c0.146-0.066,0.315-0.099,0.507-0.099h1.094c0.183,0,0.34,0.034,0.474,0.104
	c0.135,0.069,0.239,0.156,0.316,0.262s0.139,0.236,0.187,0.394c0.075,0.258,0.113,0.577,0.113,0.957
	c0,0.547-0.094,0.973-0.281,1.277c-0.187,0.305-0.456,0.457-0.809,0.457H173.1c-0.354,0-0.636-0.138-0.844-0.414
	C172.021,20.154,171.902,19.7,171.902,19.103"/>
            </g>
          </IconBase>
        );
      default:
        return null;
    }
  }

  render() {
    return this.renderGraphic();
  }
}
