import * as React from 'react';
import IconBase from 'react-icon-base';

export default class IconsSocial extends React.Component<any, any> {
  renderGraphic() {
    switch (this.props.icon) {
      case 'facebook':
        return (
          <IconBase viewBox="0 0 24 24" {...this.props}>
            <g>
              <path className="whiteMod" fill="#1877F2" d="M24,12c0-6.63-5.37-12-12-12S0,5.37,0,12c0,5.99,4.39,10.95,10.13,11.85v-8.39H7.08V12h3.05V9.36
			c0-3.01,1.79-4.67,4.53-4.67c1.31,0,2.69,0.23,2.69,0.23v2.95h-1.51c-1.49,0-1.96,0.93-1.96,1.87V12h3.33l-0.53,3.47h-2.8v8.39
			C19.61,22.95,24,17.99,24,12z"/>
              <path className="transparentMod" fill="#fff"  d="M16.67,15.47L17.2,12h-3.33V9.75c0-0.95,0.46-1.87,1.96-1.87h1.51V4.92c0,0-1.37-0.23-2.69-0.23
			c-2.74,0-4.53,1.66-4.53,4.67V12H7.08v3.47h3.05v8.39C10.74,23.95,11.36,24,12,24s1.26-0.05,1.88-0.15v-8.39H16.67z"/>
            </g>
          </IconBase>
        );
      case 'youtube':
        return (
          <IconBase viewBox="0 0 24 24" {...this.props}>
            <g>
              <path className="whiteMod" fill="#ED3024" d="M23.5,6.19c-0.28-1.04-1.09-1.86-2.12-2.14C19.51,3.55,12,3.55,12,3.55s-7.51,0-9.38,0.5
			C1.59,4.33,0.78,5.15,0.5,6.19C0,8.07,0,12,0,12s0,3.93,0.5,5.81c0.28,1.04,1.09,1.86,2.12,2.14c1.87,0.5,9.38,0.5,9.38,0.5
			s7.51,0,9.38-0.5c1.03-0.28,1.85-1.1,2.12-2.14C24,15.93,24,12,24,12S24,8.07,23.5,6.19z"/>
              <polygon className="blackMod" fill="#FFFFFF" points="9.55,15.57 9.55,8.43 15.82,12 		"/>
            </g>
          </IconBase>
        );
      case 'x':
        return (
          <IconBase viewBox="0 0 24 24" {...this.props}>
            <g>
              <path className="whiteMod" fill="#1D9BF0" id="Logo_1_" d="M24,24l-9.4-14l0,0l8.5-10h-2.8l-6.9,8.2L7.9,0H0.5l8.8,13.1l0,0L0,24h2.8l7.7-9.1l6.1,9.1H24z M6.8,2.2
                l13.2,19.6h-2.2L4.5,2.2H6.8z"/>
            </g>
          </IconBase>
        );
      case 'linkedin':
        return (
          <IconBase viewBox="0 0 24 24" {...this.props}>
            <g>
              <path className="whiteMod" fill="#0076B2" d="M22.22,0H1.77C0.79,0,0,0.77,0,1.73v20.54C0,23.22,0.79,24,1.77,24h20.45C23.2,24,24,23.22,24,22.27V1.73
				C24,0.77,23.2,0,22.22,0z"/>
              <path className="blackMod" fill="#FFFFFF" d="M3.56,9h3.56v11.45H3.56V9z M5.34,3.3c1.14,0,2.06,0.92,2.06,2.06c0,1.14-0.92,2.06-2.06,2.06
				c-1.14,0-2.07-0.92-2.07-2.06C3.27,4.23,4.2,3.3,5.34,3.3"/>
              <path className="blackMod" fill="#FFFFFF" d="M9.35,9h3.41v1.57h0.05c0.47-0.9,1.64-1.85,3.37-1.85c3.6,0,4.27,2.37,4.27,5.46v6.28h-3.56v-5.57
				c0-1.33-0.02-3.04-1.85-3.04c-1.85,0-2.13,1.45-2.13,2.94v5.66H9.35V9z"/>
            </g>
          </IconBase>
        );
      case 'instagram-white':
        return (
          <IconBase viewBox="0 0 24 24" {...this.props}>
            <g>
              <path fill="#FFFFFF" d="M12,2.16c3.2,0,3.58,0.01,4.85,0.07c1.17,0.05,1.81,0.25,2.23,0.41c0.56,0.22,0.96,0.48,1.38,0.9
			c0.42,0.42,0.68,0.82,0.9,1.38c0.16,0.42,0.36,1.06,0.41,2.23c0.06,1.27,0.07,1.64,0.07,4.85s-0.01,3.58-0.07,4.85
			c-0.05,1.17-0.25,1.81-0.41,2.23c-0.22,0.56-0.48,0.96-0.9,1.38c-0.42,0.42-0.82,0.68-1.38,0.9c-0.42,0.16-1.06,0.36-2.23,0.41
			c-1.27,0.06-1.64,0.07-4.85,0.07s-3.58-0.01-4.85-0.07c-1.17-0.05-1.81-0.25-2.23-0.41c-0.56-0.22-0.96-0.48-1.38-0.9
			c-0.42-0.42-0.68-0.82-0.9-1.38c-0.16-0.42-0.36-1.06-0.41-2.23C2.17,15.58,2.16,15.2,2.16,12s0.01-3.58,0.07-4.85
			c0.05-1.17,0.25-1.81,0.41-2.23c0.22-0.56,0.48-0.96,0.9-1.38c0.42-0.42,0.82-0.68,1.38-0.9c0.42-0.16,1.06-0.36,2.23-0.41
			C8.42,2.17,8.8,2.16,12,2.16 M12,0C8.74,0,8.33,0.01,7.05,0.07C5.78,0.13,4.9,0.33,4.14,0.63C3.35,0.94,2.68,1.35,2.01,2.01
			C1.35,2.68,0.94,3.35,0.63,4.14c-0.3,0.76-0.5,1.64-0.56,2.91C0.01,8.33,0,8.74,0,12c0,3.26,0.01,3.67,0.07,4.95
			c0.06,1.28,0.26,2.15,0.56,2.91c0.31,0.79,0.72,1.46,1.38,2.13c0.67,0.67,1.34,1.08,2.13,1.38c0.76,0.3,1.64,0.5,2.91,0.56
			C8.33,23.99,8.74,24,12,24s3.67-0.01,4.95-0.07c1.28-0.06,2.15-0.26,2.91-0.56c0.79-0.31,1.46-0.72,2.13-1.38
			c0.67-0.67,1.08-1.34,1.38-2.13c0.3-0.76,0.5-1.64,0.56-2.91C23.99,15.67,24,15.26,24,12s-0.01-3.67-0.07-4.95
			c-0.06-1.28-0.26-2.15-0.56-2.91c-0.31-0.79-0.72-1.46-1.38-2.13c-0.67-0.67-1.34-1.08-2.13-1.38c-0.76-0.3-1.64-0.5-2.91-0.56
			C15.67,0.01,15.26,0,12,0L12,0z"/>
              <path fill="#FFFFFF" d="M12,5.84C8.6,5.84,5.84,8.6,5.84,12S8.6,18.16,12,18.16s6.16-2.76,6.16-6.16S15.4,5.84,12,5.84z M12,16
			c-2.21,0-4-1.79-4-4s1.79-4,4-4s4,1.79,4,4S14.21,16,12,16z"/>
              <circle fill="#FFFFFF" cx="18.41" cy="5.59" r="1.44"/>
            </g>
          </IconBase>
        );
      case 'instagram':
        return (
          <IconBase viewBox="0 0 24 24" {...this.props}>
            <g><radialGradient id="SVGID_1_" cx="11.0049" cy="-692.3896" r="11.9975" gradientTransform="matrix(0 -1.982 -1.8439 0 -1270.3224 47.6546)" gradientUnits="userSpaceOnUse">
              <stop   offset="0" stop-color="#FFDD55"/>
              <stop  offset="0.1" stop-color="#FFDD55"/>
              <stop  offset="0.5" stop-color="#FF543E"/>
              <stop  offset="1" stop-color="#C837AB"/>
            </radialGradient>
              <path className="transparentMod" fill="url(#SVGID_1_)" d="M12.004,0.003c-5.01,0-6.475,0.006-6.76,0.029C4.216,0.117,3.576,0.279,2.879,0.626
	C2.342,0.893,1.918,1.202,1.5,1.636c-0.762,0.79-1.223,1.763-1.39,2.919C0.029,5.115,0.006,5.229,0.001,8.096
	c-0.002,0.956,0,2.213,0,3.899c0,5.006,0.005,6.47,0.029,6.754c0.083,1,0.24,1.63,0.572,2.318c0.635,1.317,1.848,2.308,3.276,2.677
	c0.495,0.127,1.041,0.197,1.743,0.23c0.297,0.013,3.326,0.022,6.356,0.022s6.061-0.004,6.352-0.019
	c0.813-0.039,1.283-0.102,1.805-0.236c1.438-0.371,2.629-1.346,3.276-2.683c0.325-0.672,0.491-1.325,0.565-2.273
	C23.993,18.58,24,15.283,24,11.992c0-3.292-0.008-6.582-0.023-6.789c-0.076-0.964-0.241-1.611-0.578-2.296
	c-0.275-0.561-0.582-0.979-1.026-1.407c-0.794-0.759-1.765-1.22-2.922-1.387c-0.561-0.081-0.672-0.105-3.54-0.11H12.004z"/>
              <radialGradient id="SVGID_2_" cx="657.4629" cy="-389.4102" r="11.9973" gradientTransform="matrix(0.1739 0.8687 3.5818 -0.7172 1276.4111 -848.6965)" gradientUnits="userSpaceOnUse">
                <stop  offset="0" stop-color="#3771C8"/>
                <stop  offset="0.128" stop-color="#3771C8"/>
                <stop  offset="1" stop-color="#6600FF" stop-opacity="0"/>
              </radialGradient>
              <path className="transparentMod" fill="url(#SVGID_2_)" d="M12.004,0.003c-5.01,0-6.475,0.006-6.76,0.029C4.216,0.117,3.576,0.279,2.879,0.626
	C2.342,0.893,1.918,1.202,1.5,1.636c-0.762,0.79-1.223,1.763-1.39,2.919C0.029,5.115,0.006,5.229,0.001,8.096
	c-0.002,0.956,0,2.213,0,3.899c0,5.006,0.005,6.47,0.029,6.754c0.083,1,0.24,1.63,0.572,2.318c0.635,1.317,1.848,2.308,3.276,2.677
	c0.495,0.127,1.041,0.197,1.743,0.23c0.297,0.013,3.326,0.022,6.356,0.022s6.061-0.004,6.351-0.019
	c0.813-0.039,1.284-0.102,1.806-0.236c1.438-0.371,2.628-1.346,3.276-2.683c0.325-0.672,0.491-1.325,0.565-2.273
	C23.993,18.58,24,15.283,24,11.992c0-3.292-0.008-6.582-0.024-6.789c-0.075-0.964-0.24-1.611-0.577-2.296
	c-0.275-0.561-0.582-0.979-1.026-1.407c-0.794-0.759-1.765-1.22-2.922-1.387c-0.561-0.081-0.672-0.105-3.541-0.11H12.004z"/>
              <path fill="#FFFFFF" d="M12.001,3.14c-2.406,0-2.708,0.011-3.653,0.054c-0.943,0.043-1.587,0.192-2.15,0.412
	C5.615,3.832,5.12,4.134,4.628,4.627C4.135,5.12,3.832,5.614,3.605,6.196c-0.22,0.563-0.37,1.208-0.412,2.15
	C3.151,9.292,3.14,9.594,3.14,12s0.011,2.707,0.054,3.652c0.043,0.943,0.193,1.587,0.412,2.15c0.227,0.582,0.529,1.077,1.022,1.569
	c0.492,0.493,0.986,0.796,1.569,1.022c0.563,0.219,1.208,0.369,2.15,0.412C9.292,20.85,9.594,20.859,12,20.859
	c2.407,0,2.708-0.01,3.653-0.053c0.943-0.043,1.588-0.193,2.151-0.412c0.583-0.227,1.076-0.529,1.568-1.022
	c0.493-0.492,0.796-0.987,1.022-1.569c0.218-0.563,0.367-1.207,0.412-2.15c0.042-0.945,0.053-1.246,0.053-3.652
	s-0.011-2.708-0.053-3.653c-0.045-0.943-0.194-1.587-0.412-2.15c-0.227-0.583-0.529-1.077-1.022-1.57s-0.985-0.795-1.569-1.021
	c-0.564-0.219-1.209-0.369-2.152-0.412c-0.944-0.043-1.245-0.054-3.652-0.054H12.001z M11.206,4.737c0.236,0,0.499,0,0.795,0
	c2.366,0,2.646,0.009,3.58,0.051c0.864,0.04,1.333,0.184,1.646,0.305c0.413,0.161,0.707,0.353,1.018,0.663
	c0.311,0.311,0.502,0.605,0.663,1.019c0.121,0.312,0.266,0.781,0.305,1.645c0.042,0.934,0.052,1.215,0.052,3.579
	c0,2.365-0.01,2.645-0.052,3.579c-0.039,0.864-0.184,1.333-0.305,1.645c-0.161,0.413-0.353,0.708-0.663,1.018
	s-0.604,0.503-1.018,0.663c-0.313,0.121-0.781,0.266-1.646,0.305c-0.935,0.043-1.214,0.053-3.58,0.053
	c-2.366,0-2.646-0.01-3.58-0.053c-0.864-0.039-1.333-0.184-1.646-0.305c-0.413-0.161-0.708-0.354-1.019-0.663
	s-0.502-0.604-0.663-1.019c-0.122-0.312-0.266-0.781-0.305-1.645c-0.042-0.934-0.051-1.215-0.051-3.581s0.008-2.645,0.051-3.579
	c0.04-0.864,0.184-1.333,0.305-1.645c0.161-0.414,0.353-0.709,0.663-1.019S6.362,5.251,6.775,5.09
	c0.313-0.122,0.782-0.266,1.646-0.305c0.817-0.037,1.134-0.048,2.785-0.05V4.737z M16.73,6.208c-0.588,0-1.063,0.476-1.063,1.063
	c0,0.587,0.476,1.063,1.063,1.063c0.587,0,1.063-0.476,1.063-1.063S17.317,6.208,16.73,6.208L16.73,6.208z M12.001,7.45
	c-2.513,0-4.55,2.037-4.55,4.55s2.037,4.549,4.55,4.549s4.55-2.036,4.55-4.549S14.514,7.45,12.001,7.45L12.001,7.45z M12.001,9.047
	c1.631,0,2.953,1.322,2.953,2.953s-1.322,2.953-2.953,2.953c-1.631,0-2.954-1.322-2.954-2.953S10.37,9.047,12.001,9.047z"/>
            </g>
          </IconBase>
        );
      default:
        return null;
    }
  }

  render() {
    return this.renderGraphic();
  }
}
