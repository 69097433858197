import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

interface LngSelectProps {
  links?: Record<string, string | undefined>,
  currentPath: string
}

class LngSelect extends React.Component<LngSelectProps & WithTranslation, any> {
  constructor(props: LngSelectProps & WithTranslation) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      dropdownOpen: false
    }
  }

  shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, _nextContext: any): boolean {
    if(this.props.i18n.language !== nextProps.lng || this.state.dropdownOpen !== nextState.dropdownOpen) {
      return true;
    }

    return false;
  }

  private toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    if(!this.props.i18n.language) {
      return false;
    }

    const renderFlagImg = (prefixe: string) => {
      if(prefixe=="EN") {
        prefixe = "GB";
      }
      return (
        <img className="flag mr-1" width={'24'} height={'24'} alt={prefixe} src={`/order/images/flags/${prefixe}.png`}/>
        );
    };

    const items = ['fr', 'en'].map((elt: any) => {
      if(elt !== this.props.i18n.language) {
        const link = this.props.links?.[elt] || this.props.currentPath?.replace(`/${this.props.i18n.language}/`, `/${elt}/`);

        return (
          <DropdownItem key={elt}>
            <a href={link}>
              {renderFlagImg(elt.toUpperCase())}
              {this.props.t(elt)}
            </a>
          </DropdownItem>
        );
      }
    });

    return (
      <>
      <Dropdown className="select-language-container" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret className="d-flex align-items-center  black border-0 shadow-none bg-transparent" aria-label="Choisir ma langue">
          {renderFlagImg(this.props.i18n.language.toUpperCase())}
        </DropdownToggle>
        <DropdownMenu>
          {items}
        </DropdownMenu>
      </Dropdown>
        </>
    );
  }
}

export default withTranslation()(LngSelect);
