import React from 'react';
import Icons from './Icons';
import userStore from 'tunnel-commande/src/components/mon-compte/UserStore';
import {WithTranslation, withTranslation} from "react-i18next";
import { isEqual } from '../utils';

type AccountIconProps = {
  smallDevice: boolean,
  mobileMenu?: boolean
} & WithTranslation;

export class AccountIcon extends React.Component<AccountIconProps, any> {
  constructor(props: AccountIconProps) {
    super(props);

    this.handleUserStoreChange = this.handleUserStoreChange.bind(this);

    this.state = {
      user: userStore.getUser()
    };
  }

  shouldComponentUpdate(_nextProps: Readonly<any>, nextState: Readonly<any>, _nextContext: any): boolean {
    if(!isEqual(nextState.user, this.state.user)  || !isEqual(_nextProps.smallDevice, this.props.smallDevice)) {
      return true;
    }

    return false;
  }

  componentDidMount(): void {
    userStore.addListener('change', this.handleUserStoreChange);
  }

  componentWillUnmount(): void {
    userStore.removeListener('change', this.handleUserStoreChange);
  }

  private handleUserStoreChange() {
    this.setState({
      user: userStore.getUser()
    });
  }

  render() {
    const href: string = (this.state.user) ? `/${this.props.i18n.language}/mon-compte` : `/${this.props.i18n.language}/login`;
    const color = this.props.smallDevice ? 'white' : 'darkGrey' ;
    const size = this.props.smallDevice ? 25 : 25 ;
    return (
      <a href={href} title={this.props.t('go_to_account_btn')}>
        <span className="cursor-pointer blocMenuCompte p-1 py-2 d-flex align-items-center">
          <Icons icon="compte" size={size} className={`shortIconMenu color mr-2 ml-2 `+color}/>
          {(this.state.user && !this.props.smallDevice) &&
          <div className={'d-flex flex-column justify-content-start mr-2'}>
            <span className={'badge badge-pill text-uppercase cursor-pointer text-left'}>
               {this.props.t('my_account')}
            </span>
            <span className={'badge badge-pill cursor-pointer text-left'}>
                 <span className="d-none d-lg-block text-capitalize">{this.state.user.Prenom}</span>
              </span>
          </div>
          }
          {(this.props.mobileMenu && this.props.smallDevice) &&
           <>{this.props.t('my_account')}</>
          }
        </span>
      </a>
    );
  }
}

export default withTranslation('common')(AccountIcon);
