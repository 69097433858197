import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { withSSR } from 'react-i18next';
import { loadableReady } from '@loadable/component'
import { StaticRouter } from 'react-router-dom';
import App from "../src/App";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-modal/src/styles.css';
import "swiper/swiper.min.css";
import "swiper/components/lazy/lazy.min.css";
import './main.scss';
import 'tunnel-commande/src/components/cart/style.css';
import 'tunnel-commande/src/components/mon-compte/style.css';
import 'tunnel-commande/lib/css/faq.css';

import {RouteContext} from "../src/contexts/RouteContext";
import {HostApiContext} from "../src/contexts/HostApiContext";
import {ShopInfoContext} from "../src/contexts/ShopInfoContext";

declare let window: any;

const context = window._CONTEXT_;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: context.i18n.lng,
    fallbackLng: 'fr',
    preload: [context.i18n.lng],
    ns: ['common', 'footer', 'login', 'faq', 'aboutUs', 'index', 'customerReset', 'cart'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: (value, format, lng) => {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
    react: {
      useSuspense: false,
      wait: true
    }
});
i18n.changeLanguage(context.i18n.lng);

const ExtendedApp = withSSR()(App);

window['hostApi'] = context.hostApi;
localStorage.setItem('hostApi', context.hostApi);
sessionStorage.setItem('shopInfos', JSON.stringify(context.shopInfo));

loadableReady(() => {
  ReactDOM.hydrate(
    <ShopInfoContext.Provider value={context.shopInfo}>
      <HostApiContext.Provider value={context.hostApi}>
        <RouteContext.Provider value={context.route}>
          <StaticRouter location={context.shadowRedirect || context.route.url}>
            <ExtendedApp initialI18nStore={context.i18n.store} initialLanguage={context.i18n.lng}
                         context={context}/>
          </StaticRouter>
        </RouteContext.Provider>
      </HostApiContext.Provider>
    </ShopInfoContext.Provider>,
    document.getElementById('containerApp'),
    () => {
      //removeStyles();
    }
  );
});
