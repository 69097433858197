import loadable from '@loadable/component';
import { RouteConfig } from './interfaces';

const Routes: RouteConfig[] = [
  {
    path: "/:lng",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Index" */ './pages/Index')),
    hook: 'index'
  },
  {
    path: "/:lng/p-:title([A-Za-z0-9-/]+)?-:id([0-9]+)",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Produit" */ './pages/Produit')),
    hook: 'produit'
  },
  {
    path: "/:lng/c-:title([A-Za-z0-9-/]+)?-:id([0-9]+)",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Categories" */ './pages/Categories')),
    hook: 'categories'
  },
  {
    path: "/:lng/cart",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Cart" */ './pages/Cart'))
  },
  {
    path: "/:lng/login",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Login" */ './pages/Login'))
  },
  {
    path: "/:lng/customer-reset",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "CustomerReset" */ './pages/CustomerReset'))
  },
  {
    path: "/:lng/mon-compte",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "MonCompte" */ './pages/MonCompte'))
  },
  {
    path: "/:lng/about-us",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "AboutUs" */ './pages/AboutUs'))
  },
  {
    path: "/:lng/faq",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Faq" */ './pages/Faq')),
    hook: 'redirectFAQ'
  },
  {
    path: "/:lng/faq/:tag",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Faq" */ './pages/Faq')),
    hook: ['staticPage', 'returnsPolicy']
  },
  {
    path: "/:lng/help",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Help" */ './pages/Help')),
    hook: ['faqStaticContents', 'staticPage', 'help']
  },
  {
    path: "/:lng/help/q-:question([A-Za-z0-9-/]+)/:id([0-9]+)",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Question" */ './pages/Question')),
    hook: ['faqStaticContents', 'question']
  },
  {
    path: "/:lng/help/:label([A-Za-z0-9-/]+)/:id([0-9]+)",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "Thematique" */ './pages/Thematique')),
    hook: ['faqStaticContents', 'thematique']
  },
  {
    path: "/:lng/error",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "UnknowError" */ './pages/UnknowError'))
  },
  {
    path: "/:lng/password-reset",
    exact: true,
    component: loadable(() => import(/* webpackChunkName: "PasswordReset" */ './pages/PasswordReset'))
  },
  {
    path: "/:lng/*",
    component: loadable(() => import(/* webpackChunkName: "NotFound" */ './pages/NotFound')),
    hook: 'notfound'
  }
];


export default Routes;
