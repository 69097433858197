import React from "react";

export const HostApiContext = React.createContext<string>(null as any);

export interface HostApiProps {
  hostApi: string
}

export function withHostApi<T>(WrappedComponent: React.ComponentType<T & HostApiProps>) {
  const WithHostApiFn = (props: Omit<T, keyof HostApiProps>) => {
    return (
      <HostApiContext.Consumer>
        {(value) => {
          return <WrappedComponent {...(props as T)} hostApi={value}/>
        }}
      </HostApiContext.Consumer>
    )
  };

  WithHostApiFn.WrappedComponent = WrappedComponent;

  return WithHostApiFn;
}
