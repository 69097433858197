import React from "react";
import {RouteCtx} from "../interfaces";

export const RouteContext = React.createContext<RouteCtx>(null as any);

export interface RouteProps {
  route: RouteCtx
}

export function withRoute<T>(WrappedComponent: React.ComponentType<T & RouteProps>) {
  const WithRoute = (props: Omit<T, keyof RouteProps>) => {
    return (
      <RouteContext.Consumer>
        {(value) => {
          return <WrappedComponent {...(props as T)} route={value}/>
        }}
      </RouteContext.Consumer>
    )
  };

  WithRoute.WrappedComponent = WrappedComponent;

  return WithRoute;
}
