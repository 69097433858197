import React, { FunctionComponent, useState } from 'react';
import Icons from './Icons';

interface InfoBannerProps {
  content: string;
}

const InfoBanner: FunctionComponent<InfoBannerProps> = ({content}) => {
  const [show, setShow] = useState<boolean>(true);

  const getCloseClass = () => (content === '' || !show) ? 'close-banner' : '';

  return (
    <div className={`banner-info p-2 position-relative ${getCloseClass()}`}>
      <span dangerouslySetInnerHTML={{__html: content}}></span>
      <div
        className="icon-close display-flex cursor-pointer"
        onClick={() => setShow(false)}
      >
        <Icons icon="close" />
      </div>
    </div>
  );
};

export default InfoBanner;
