import Axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { Switch } from "react-router";
import { renderRoutes } from "react-router-config";
import {getRequestConfig} from "tunnel-commande/src/utils";
import Nav from "./components/Nav";
import NavFooter from "./components/NavFooter";
import GTag from "./components/GTag";
import Bing from "./components/Bing";
import routes from "./routes";
import {HostApiProps, withHostApi} from "./contexts/HostApiContext";
import {logout, recovery} from "tunnel-commande/src/components/mon-compte/MonCompteAction";
import {createCart, getCart} from "tunnel-commande/src/components/cart/CartAction";
import Bandeau from "./components/Bandeau";
import {ShopInfoProps, withShopInfo} from "./contexts/ShopInfoContext";
import CookieBot from "./components/CookieBot";
import LiveChat from "./components/LiveChat";
import LiveAgent from "./components/LiveAgent";
import {schemaDT} from "./utils";
import userStore from "tunnel-commande/src/components/mon-compte/UserStore";
import InfoBanner from './components/InfoBanner';

declare let window: any;

type AppProps = {
  context: Record<string, any>;
} & HostApiProps & ShopInfoProps

class App extends React.Component<AppProps, any> {

  public constructor(props: AppProps) {
    super(props);

    this.onCollapsedMenu = this.onCollapsedMenu.bind(this);

    this.state = {
      collapsed: false,
      liveAgent: false
    };
  }

  componentDidMount() {
    if(['production'].includes(this.props.shopInfo.env)) {
      console.error = (...data: any[]) => { /** */ };
      console.warn = (...data: any[]) => { /** */ };
    }
    this.featureLiveAgentTchat(this.props.hostApi);

    if(this.props.context?.route.query?.admin_login_token) {
      const token = this.props.context?.route.query?.admin_login_token;

      Axios.post(`${this.props.hostApi}/customers/admin-auth`, {token: token}, getRequestConfig())
        .then((res) => {
          console.log(res);
          sessionStorage.setItem('customerStruct', JSON.stringify(res.data.customer));
          localStorage.setItem('xsrfToken', res.data.xsrfToken);
          userStore.setUser(res.data.customer);

          let href = "/mon-compte";
          if(this.props.context?.route.query?.redirect) {
            href = `${href}#${this.props.context?.route.query?.redirect}`;
          }
          window.location.href=href;
        }).catch((err) => {
        console.error(err);
      });
    } else {
      this.syncTokenStruct(this.props.context.hostApi).then((tokenStruct) => {
        localStorage.setItem('xsrfToken', tokenStruct['xsrfToken']);

        this.syncCartStruct(tokenStruct['new']);

        if(window.location.href.indexOf('?deconnexion') !== -1) {
          return logout();
        } else {
          return recovery();
        }
      }).catch((err) => { /** */ });
    }
  }

  /**
   * Récupère l'état de la feature liveagent_DT
   * @private
   */
  private featureLiveAgentTchat(hostApi: string) {
    Axios.get(`${hostApi}/shops/features/liveagent_DT`, getRequestConfig()).then((response) => {
      this.setState({
        liveAgent: response.data.feature
      })
    }).catch((error) => {
      console.log(error);
    });
  }


  private async syncTokenStruct(hostApi: string) {
    const result = await Axios.post(`${hostApi}/auth/token`, {}, getRequestConfig());
    return result.data.token_struct;
  }

  private onCollapsedMenu(collapsed: any) {
    this.setState({
      collapsed: collapsed
    });
  }

  private syncCartStruct(newToken: boolean) {
    let cart = null;

    try {
      const localStorageCart: any = localStorage.getItem('cartStruct');
      cart = JSON.parse(localStorageCart);
    } catch (err) {
      console.log(err);
    }

    if (!newToken) {
      if (cart) {
        (getCart() as any).catch(() => createCart());
        return;
      }
    }

    return createCart();
  }



  public render() {
    let classTypePage = '';
    let idCategorie = 0;
    if(this.props.context.route.url == '/') {
      classTypePage = 'page-home';
    } else if (this.props.context.route.url == '/about-us') {
      classTypePage = 'page-about-us';
    } else if (this.props.context.route.url == '/videos') {
      classTypePage = 'page-videos';
    } else if (this.props.context.route.url == '/produit') {
      classTypePage = 'page-produit';
    } else if (this.props.context.route.url == '/categories' && this.props.context?.category?.IdCategorie != "") {
      classTypePage = 'page-category page-category-'+this.props.context?.category?.IdCategorie;
      idCategorie = this.props.context?.category?.IdCategorie;
    }

    let classIfCollapsedMenu = '';
    let classIfCollapsedMenu2 = '';
    if(this.state.collapsed) {
      classIfCollapsedMenu = 'wrapper-dt-collapsed-menu';
      classIfCollapsedMenu2 = 'dt-collapsed-menu';
    }

    return (
      <>
        <Helmet htmlAttributes={{lang: this.props.context.i18n.lng}}>
          <title>DuckTransfer</title>
          <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          <link rel="manifest" href="manifest.json" />
          <link rel="icon" type="image/png" href="/images/manifest/icon-72x72.png" />
          <link rel="apple-touch-icon" href="/images/manifest/icon-32x32.png" />
          <link rel="apple-touch-icon" sizes="57x57" href="/images/manifest/icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/images/manifest/icon-72x72.png"/>
          <link rel="apple-touch-icon" sizes="76x76" href="/images/manifest/icon-76x76.png"/>
          <link rel="apple-touch-icon" sizes="114x114" href="/images/manifest/icon-114x114.png"/>
          <link rel="apple-touch-icon" sizes="120x120" href="/images/manifest/icon-120x120.png"/>
          <link rel="apple-touch-icon" sizes="144x144" href="/images/manifest/icon-144x144.png"/>
          <link rel="apple-touch-icon" sizes="152x152" href="/images/manifest/icon-152x152.png"/>
          <link rel="apple-touch-icon" sizes="180x180" href="/images/manifest/icon-180x180.png"/>
          <link rel="preconnect" crossOrigin="anonymous" href="https://api.ducktransfer.com" />
          <meta name="msapplication-TileColor" content="#f07e26" />
          <meta name="msapplication-TileImage" content="/public/images/manifest/icon-144x144.png" />
          <meta name="application-name" content="DuckTransfer" />
          <meta name="theme-color" content="#000000" />
          <meta name="msapplication-navbutton-color" content="#E92328" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
          <link rel="preload" href="https://www.ducktransfer.com/fonts/carterone-regular-webfont.woff" />
          <link rel="preload" href="https://www.ducktransfer.com/fonts/opensans-bold-webfont.woff" />
          <link rel="preload" href="https://www.ducktransfer.com/fonts/opensans-regular-webfont.woff" />
          <meta name="twitter:card" content="summary"/>
          <meta name="twitter:site" content="@DuckTransfer"/>
          <script src="https://code.jquery.com/jquery-3.6.0.slim.min.js"
                  integrity="sha256-u7e5khyithlIdTpu22PHhENmPcRdFiHRjhAuHcs05RI=" crossOrigin="anonymous"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossOrigin="anonymous"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ho+j7jyWK8fNQe+A12Hb8AhRq26LrZ/JpcUGGOn+Y7RsweNrtN/tE3MoK7ZeZDyx" crossOrigin="anonymous"></script>
        </Helmet>
        <Bandeau env={this.props.shopInfo.env} />
        <div className={'wrapper-dt  '+classTypePage+' '+classIfCollapsedMenu}>
          <header className={'container-fluid p-0 m-0 h-100'}>
            <InfoBanner content={this.props.context.bannerContent} />
            <Nav onCollapsed={this.onCollapsedMenu} categories={this.props.context.categories} categorie={idCategorie}
                 currentPath={this.props.context.route.url} links={this.props.context.links} />
          </header>

          <main className={'container-fluid m-0 p-0 p-0 h-100  position-relative ' + classIfCollapsedMenu2}>
            <Switch>
                {renderRoutes(routes as any, {context: this.props.context})}
            </Switch>
          </main>

          <footer className={`container-fluid pt-4 px-0 ${classIfCollapsedMenu2}`}>
            <NavFooter isNewFaqEnabled={this.props.shopInfo.newFaqIsEnabled} />
          </footer>
          {typeof window !== 'undefined' &&
            <LiveChat license={10075743}/>
          }
          {typeof window !== 'undefined' &&  this.state.liveAgent &&
            <LiveAgent />
          }
        </div>



        <script data-cookieconsent="ignore" dangerouslySetInnerHTML={ { __html: `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 2000,
    });
    gtag("set", "ads_data_redaction", true);
    `}} />


        
        <Bing bingid={'22021288'} />
        <GTag analyticsId={"UA-91403-9"} gtagId={"AW-1072356920"}/>
        {schemaDT()}
          <script dangerouslySetInnerHTML={ { __html: `          var _paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//piwik.designpartner.fr/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '36']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}} />
        <script defer src="https://www.paypalobjects.com/api/checkout.min.js" data-version-4></script>
        <script data-namespace="paypal_sdk"  data-cookieconsent="ignore"  src={`https://www.paypal.com/sdk/js?currency=EUR&commit=false&client-id=${this.props.context.shopInfo.paypal_client_id}`}></script>
      </>
    );
  }
}


export default withShopInfo(withHostApi(App));
